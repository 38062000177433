import { addClass } from '../../tools/helpers';
import ConsentIFrame from '../ConsentIFrame';

class ContentAccessRestriction {
  constructor(options = {}) {
    this.element = options.element;
    this.contentIntro = document.getElementById('contentIntro');
    this.acceptButton = document.getElementById('loadContent');
    this.contentTemplate = document.getElementById('contentTemplate');
    this.contentContainer = document.getElementById('contentContainer');
  }

  init() {
    this.acceptButton.addEventListener('click', (event) => {
      event.preventDefault();
      addClass(this.contentIntro, 'hidden');
      const clone = document.importNode(this.contentTemplate.content, true);
      this.contentContainer.appendChild(clone);

      // Initialize Consent
      this.initializeConsentIFrame();
    });
  }

  initializeConsentIFrame() {
    const consentElements = this.contentContainer.querySelectorAll('[data-module="ConsentIFrame"]');
    consentElements.forEach((element) => {
      const iframeId = element.getAttribute('data-iframe-id');
      const consentIFrame = new ConsentIFrame({
        element,
        iframeId,
      });
      consentIFrame.init();
    });
  }
}

export default ContentAccessRestriction;
