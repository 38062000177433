import arrayFrom from 'array-from';

import DefaultNavigation from '../DefaultNavigation';
import { addClass, hasClass, removeClass, toggleClass } from '../../tools/helpers';

export const CLASSNAME_ACTIVE = 'is-active';
export const CLASSNAME_OPENED = 'opened';

class SidebarNavigation extends DefaultNavigation {
  constructor(options = {}) {
    super(options);
    this.navigationChevron = this.element.querySelector('a.navigation-chevron');
  }

  init() {
    super.init();
  }

  bindEvents() {
    document.querySelector('body').addEventListener('click', () => {
      if (arrayFrom(this.level1Items).some(item => hasClass(item, `${CLASSNAME_ACTIVE}`))) {
        this.closeAllSubNavigations();
      }
    });

    if (this.navigationChevron !== null) {
      this.navigationChevron.addEventListener('click', () => {
        if (this.navigationChevron.getAttribute('aria-expanded') === 'false') {
          this.navigationChevron.setAttribute('aria-expanded', true);
        } else if (this.navigationChevron.getAttribute('aria-expanded') === 'true') {
          this.navigationChevron.setAttribute('aria-expanded', false);
        }

        toggleClass(this.navigationChevron, 'collapsed');
        toggleClass(this.element.querySelector('.wrapper-navigation-sidebar'), 'in');
      });
    }

    arrayFrom(this.level1Items).forEach((item) => {
      item.addEventListener('click', (event) => {
        event.stopPropagation();
        this.closeAllSubNavigations(item);

        if (!hasClass(item.parentNode, `${CLASSNAME_ACTIVE}`)) {
          this.openSubNavigation(item);
        } else if (hasClass(item.parentNode, `${CLASSNAME_ACTIVE}`)) {
          this.closeSubNavigation(item);
        }
      });
    });
  }

  closeAllSubNavigations(excludedItem) {
    arrayFrom(this.level1Items).filter(item => item !== excludedItem).forEach((item) => {
      this.closeSubNavigation(item);
    });
  }

  openSubNavigation(item) {
    addClass(item.parentNode, `${CLASSNAME_ACTIVE}`);
    const subNavigation = item.parentNode.querySelector('.navbar__nav-item__sub-navigation');

    if (subNavigation !== null) {
      addClass(subNavigation, `${CLASSNAME_OPENED}`);
      subNavigation.setAttribute('aria-expanded', true);
    }
  }

  closeSubNavigation(item) {
    removeClass(item.parentNode, `${CLASSNAME_ACTIVE}`);

    const subNavigation = item.parentNode.querySelector('.navbar__nav-item__sub-navigation');

    if (subNavigation !== null) {
      removeClass(subNavigation, `${CLASSNAME_OPENED}`);
      subNavigation.setAttribute('aria-expanded', false);
    }
  }

  addScrollListener() {
    return false;
  }
}

export default SidebarNavigation;
