import moment from 'moment';
import arrayFrom from 'array-from';
import TableSort from 'tablesort';

import { generateSVGIcon, toggleClass } from '../../../tools/helpers';

/**
 * Fixed Labels for different Display-Options.
 * @param {*} lang - The lang set in the constructor.
 */
const fixedLabels = (lang) => {
  let langLabels = null;
  switch (lang) {
    case 'de':
    default:
      langLabels = {
        none: ['Datum', 'Uhrzeit'],
        quarterhour: ['Datum', 'Uhrzeit'],
        day: ['Datum'],
        month: ['Datum'],
        yearPeak: ['Jahr', 'Zeitpunkt', 'Uhrzeit'],
      };
      break;
    case 'en':
      langLabels = {
        none: ['Date', 'Time'],
        quarterhour: ['Date', 'Time'],
        day: ['Date'],
        month: ['Date'],
        yearPeak: ['Year', 'Moment', 'Time'],
      };
  }

  return langLabels;
};

const returnConditionalDecimal = (showDecimals, langCode, value) => {
  let returnValue = null;
  if (showDecimals) {
    returnValue = Intl.NumberFormat(langCode, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);
  } else {
    returnValue = Intl.NumberFormat().format(value);
  }

  return returnValue;
};

const isDayVisible = display => display === 'quarterhour';

const dateColumnVisible = (display, type) => {
  if (type === 'AUSFAELLE') {
    return false;
  }

  if (type === 'REDISPATCH') {
    return false;
  }

  return display !== 'none';
};

const timeColumnVisible = (display, type) => {
  if (type === 'AUSFAELLE') {
    return false;
  }

  if (type === 'REDISPATCH') {
    return false;
  }

  return isDayVisible(display);
};


class GridDataTable {
  constructor(options = {}) {
    this.element = options.element;
    this.display = options.display;
    this.type = options.type;
    this.data = options.data;
    this.viewOptions = options.viewOptions;
    this.lang = document.querySelector('html').lang;
    this.langCode = this.lang === 'de' ? 'de-DE' : 'en-IN';
  }

  init() {
    if (this.viewOptions.reverseOrder === 'true') {
      if (this.viewOptions.isHistory === 'true') {
        this.data.items = this.data.items.reverse();
      } else {
        this.data.items.items = this.data.items.items.reverse();
      }
    }

    if (this.viewOptions.isHistory === 'true') {
      this.renderHistoryTable();
    } else if (this.viewOptions.isYearPeak === 'true') {
      this.renderYearPeakTable();
    } else {
      this.renderDefaultTable();
    }
  }

  /**
   * Render the Default Table
   */
  renderDefaultTable() {
    let table = '<table class="table table-striped">';
    let tableHead = '<thead>';

    this.labels = this.viewOptions.labels.split(';');

    if (this.display !== null) {
      if (this.display === 'none' && dateColumnVisible(this.display, this.type) && timeColumnVisible(this.display, this.type)) {
        fixedLabels(this.lang).none.reverse().forEach((value) => {
          this.labels.unshift(value);
        });
      } else if (this.display === 'quarterhour') {
        fixedLabels(this.lang).quarterhour.reverse().forEach((value) => {
          this.labels.unshift(value);
        });
      } else if (this.display === 'day') {
        fixedLabels(this.lang).day.reverse().forEach((value) => {
          this.labels.unshift(value);
        });
      } else if (this.display === 'month') {
        fixedLabels(this.lang).month.reverse().forEach((value) => {
          this.labels.unshift(value);
        });
      }
    }

    this.labels.forEach((label) => {
      if (label !== null) {
        const labelTh = `<th>${label}</th>`;
        tableHead += labelTh;
      }
    });

    tableHead += '</thead>';

    let tableBody = '<tbody>';

    this.data.items.items.forEach((item) => {
      let bodyRow = '<tr>';
      if (item.date !== null && dateColumnVisible(this.display, this.type)) {
        const dateTd = `<td>${moment(item.date, 'DD.MM.YYYY').format('DD.MM.YYYY')}</td>`;
        bodyRow += dateTd;
      }

      if (item.timeRange.length > 0 && timeColumnVisible(this.display, this.type)) {
        const timeRangeTd = `<td>${item.timeRange}</td>`;
        bodyRow += timeRangeTd;
      }

      if (item.additional !== null) {
        item.additional.forEach((additional) => {
          const additionalTd = `<td>${additional === null ? '' : additional}</td>`;
          bodyRow += additionalTd;
        });
      }

      if (item.values !== null) {
        item.values.forEach((value) => {

          let valueTd = '<td>';
          if (value === null) {
            valueTd += '-';
          } else {
            valueTd += returnConditionalDecimal(this.viewOptions.showDecimalTwo, this.langCode, value);
          }

          valueTd += '</td>';

          bodyRow += valueTd;
        });
      }

      bodyRow += '</tr>';

      tableBody += bodyRow;
    });

    tableBody += '</tbody>';

    table += tableHead;
    table += tableBody;

    table += '</table>';

    this.element.querySelector(`.${this.viewOptions.baseClass}__table`).innerHTML = '';

    this.element.querySelector(`.${this.viewOptions.baseClass}__table`).insertAdjacentHTML('beforeend', table);

    if (this.viewOptions.sortable === 'true') {
      this.addSortable();
    }
  }

  /**
   * Render the Year Peak Table
   */
  renderYearPeakTable() {
    let table = '<table class="table table-striped">';
    let tableHead = '<thead>';

    this.labels = [];

    if (this.display !== null) {
      if (this.display === 'none' && this.viewOptions.isYearPeak === 'true') {
        fixedLabels(this.lang).yearPeak.forEach((value) => {
          this.labels.push(value);
        });
      }
    }

    this.viewOptions.labels.split(';').forEach((dataLabel, i) => {
      this.labels.splice(i + 1, 0, dataLabel);
    });

    this.labels.forEach((label) => {
      if (label !== null) {
        const labelTh = `<th>${label}</th>`;
        tableHead += labelTh;
      }
    });

    tableHead += '</thead>';

    let tableBody = '<tbody>';

    this.data.items.items.forEach((item) => {
      let bodyRow = '<tr>';

      if (item.date !== null) {
        const yearTd = `<td>${moment(item.date, 'DD.MM.YYYY').format('YYYY')}</td>`;
        bodyRow += yearTd;
      }

      if (item.additional !== null) {
        item.additional.forEach((additional) => {
          const additionalTd = `<td>${additional === null ? '' : additional}</td>`;
          bodyRow += additionalTd;
        });
      }

      if (item.values !== null) {
        item.values.forEach((value) => {
          let valueTd = '<td>';
          if (value === null) {
            valueTd += '-';
          } else {
            valueTd += returnConditionalDecimal(this.viewOptions.showDecimalTwo, this.langCode, value);
          }

          valueTd += '</td>';

          bodyRow += valueTd;
        });
      }

      if (item.date !== null) {
        const dateTd = `<td>${moment(item.date, 'DD.MM.YYYY').format('DD.MM.YYYY')}</td>`;
        bodyRow += dateTd;
      }

      if (item.timeRange.length > 0) {
        const timeRangeTd = `<td>${item.timeRange}</td>`;
        bodyRow += timeRangeTd;
      }

      bodyRow += '</tr>';

      tableBody += bodyRow;
    });

    tableBody += '</tbody>';

    table += tableHead;
    table += tableBody;

    table += '</table>';

    this.element.querySelector(`.${this.viewOptions.baseClass}__table`).innerHTML = '';

    this.element.querySelector(`.${this.viewOptions.baseClass}__table`).insertAdjacentHTML('beforeend', table);

    if (this.viewOptions.sortable === 'true') {
      this.addSortable();
    }
  }

  /**
   * Render the History Table
   */
  renderHistoryTable() {
    let table = '<table class="table table-striped table-history">';
    let tableHead = '<thead>';

    this.labels = this.viewOptions.labels.split(';');
    if (this.display !== null) {
      if (this.display === 'none' && this.viewOptions.isHistory !== 'true') {
        fixedLabels(this.lang).none.reverse().forEach((value) => {
          this.labels.unshift(value);
        });
      } else if (this.display === 'quarterhour') {
        fixedLabels(this.lang).quarterhour.reverse().forEach((value) => {
          this.labels.unshift(value);
        });
      } else if (this.display === 'day') {
        fixedLabels(this.lang).day.reverse().forEach((value) => {
          this.labels.unshift(value);
        });
      } else if (this.display === 'month') {
        fixedLabels(this.lang).month.reverse().forEach((value) => {
          this.labels.unshift(value);
        });
      }
    }

    if (this.data.items.some(it => it.versionHistory.length > 0)) {
      const arrowTd = '<td class="arrow-holder"></td>';
      tableHead += arrowTd;
    }

    this.labels.forEach((label) => {
      if (label !== null) {
        const labelTh = `<th>${label}</th>`;
        tableHead += labelTh;
      }
    });

    tableHead += '</thead>';

    let tableBody = '<tbody>';

    this.data.items.forEach((item, index) => {
      if (item.latestVersion !== null) {
        let bodyRow = `<tr class="latest-version latest-version-row-${index}">`;

        if (this.data.items.some(it => it.versionHistory.length > 0)) {
          let arrowTd = '<td class="arrow-holder">';
          if (item.versionHistory.length > 0) {
            arrowTd += `<button>${generateSVGIcon('icon-arrow-to-top')}</button>`;
          }

          arrowTd += '</td>';
          bodyRow += arrowTd;
        }

        if (item.latestVersion.additional !== null) {
          item.latestVersion.additional.forEach((additional) => {
            const additionalTd = `<td>${additional === null ? '' : additional}</td>`;
            bodyRow += additionalTd;
          });
        }

        if (item.latestVersion.values !== null) {
          item.latestVersion.values.forEach((value) => {
            let valueTd = '<td>';
            if (value === null) {
              valueTd += '-';
            } else {
              valueTd += returnConditionalDecimal(this.viewOptions.showDecimalTwo, this.langCode, value);
            }

            valueTd += '</td>';

            bodyRow += valueTd;
          });
        }

        bodyRow += '</tr>';

        tableBody += bodyRow;
      }

      if (item.versionHistory !== null) {
        item.versionHistory.reverse().forEach((historyItem) => {
          let bodyRow = `<tr class="version-history version-history-row-${index}">`;

          if (this.data.items.some(it => it.versionHistory.length > 0)) {
            const arrowTd = '<td class="arrow-holder"></td>';
            bodyRow += arrowTd;
          }

          if (historyItem.additional !== null) {
            historyItem.additional.forEach((additional) => {
              const additionalTd = `<td>${additional === null ? '' : additional}</td>`;
              bodyRow += additionalTd;
            });
          }

          if (historyItem.values !== null) {
            historyItem.values.forEach((value) => {
              const valueTd = `<td>${value === null ? '-' : Intl.NumberFormat(this.viewOptions.showDecimalTwo === true ? `${this.langCode}, { maximumFractionDigits: 2 }` : '').format(value)}</td>`;
              bodyRow += valueTd;
            });
          }

          bodyRow += '</tr>';
          tableBody += bodyRow;
        });
      }
    });

    tableBody += '</tbody>';

    table += tableHead;
    table += tableBody;

    table += '</table>';

    this.element.querySelector(`.${this.viewOptions.baseClass}__table`).innerHTML = '';

    this.element.querySelector(`.${this.viewOptions.baseClass}__table`).insertAdjacentHTML('beforeend', table);

    if (this.viewOptions.sortable === 'true') {
      this.addSortable();
    }

    this.addEvents();
  }

  /**
   * Add the Sortable Function to the Table.
   */
  addSortable() {
    const table = this.element.querySelector(`.${this.viewOptions.baseClass} table`);

    arrayFrom(table.querySelectorAll('th')).forEach((th, index) => {
      if (index !== parseInt(this.viewOptions.sortableColumn, 10)) {
        th.setAttribute('data-sort-method', 'none');
      }
    });
    // eslint-disable-next-line
    const tableSort = new TableSort(table);
  }

  /**
   * Add Events for History Indicator Toggle.
   */
  addEvents() {
    const table = this.element.querySelector(`.${this.viewOptions.baseClass} table`);

    arrayFrom(table.querySelectorAll('tr[class*="latest-version-row"]')).forEach((row, index) => {
      if (table.querySelectorAll(`.version-history-row-${index}`)) {
        row.addEventListener('click', () => {
          toggleClass(row, 'is-active');

          const rows = arrayFrom(table.querySelectorAll(`.version-history-row-${index}`));
          rows.forEach((rw) => {
            toggleClass(rw, 'is-active');
          });
        });
      }
    });
  }
}

export default GridDataTable;
