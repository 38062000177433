import { addClass, findParent } from '../../tools/helpers';
import ConsentManager from '../ConsentManager';
import TemplateHandler from '../TemplateHandler';

class ConsentVideo {
  constructor(options = {}) {
    this.element = options.element;
    this.parentSelector = options.parent || 'mol--video-item__iframe-content';
    this.placeholderSelector = options.placeholder || '.mol--video-item__placeholder-image';
    this.parent = findParent(this.element, this.parentSelector);
    this.consentNeeded = true;

    this.consentContent = TemplateHandler.getTemplate('consent-overlay-content', this.element);
    this.mediaContent = TemplateHandler.getTemplate('consent-given-content', this.element);
  }

  init() {
    this.initConsentManager();
  }

  initConsentManager() {
    // console.log('Init COnsent Manaager in FUnction', this.consentNeeded);
    if (this.consentNeeded) {
      this.consentManager = new ConsentManager({
        element: this.element,
        consentTemplateId: this.element.querySelector('[data-template-name="consent-overlay-content"]').dataset.templateId,
        consentDataProcessor: this.element.querySelector('[data-template-name="consent-overlay-content"]').dataset.processor,
        callbackFail: () => this.insertConsent(),
        callbackSuccess: () => this.insertContent(),
        callbackReinit: () => this.init(),
      });

      this.consentManager.init();
    }
  }

  insertConsent() {
    const content = document.createElement('section');
    addClass(content, 'consent__slot');
    content.insertAdjacentHTML('beforeend', this.consentContent);

    if (!this.parent.querySelector('.consent__wrapper')) {
      this.parent.appendChild(content);
      this.addLayerEvents();
    }
  }

  insertContent() {
    const content = document.createElement('section');
    content.insertAdjacentHTML('beforeend', this.mediaContent);
    addClass(this.parent.querySelector(this.placeholderSelector), 'hidden');

    if (this.parent.querySelector('.consent__slot')) {
      this.parent.removeChild(this.parent.querySelector('.consent__slot'));
    }

    this.element.parentNode.insertAdjacentHTML('beforeend', this.mediaContent);
  }

  addLayerEvents() {
    const buttonAccept = this.parent.querySelector('.give-consent');
    if (buttonAccept !== null) {
      buttonAccept.addEventListener('click', () => {
        this.consentManager.giveConsent();
      });
    }

    const buttonInfo = this.parent.querySelector('.more-info');
    if (buttonInfo !== null) {
      const templateId = this.element.querySelector('[data-template-name="consent-overlay-content"]').dataset.templateId;
      buttonInfo.addEventListener('click', () => {
        this.consentManager.toggleConsentWindow(templateId);
      });
    }
  }
}

export default ConsentVideo;
