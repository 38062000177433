import Swiper from 'swiper/bundle';
import Systemvison2050PartnerTeaser from '../Systemvison2050PartnerTeaser';

class PartnerSlider {
  constructor(options = {}) {
    this.element = options.element;
    this.swiperContainer = this.element.querySelector('.swiper-container');
  }

  init() {
    this.partnerSlider = new Swiper(this.swiperContainer, {
      slidesPerView: 1,
      spaceBetween: 30,
      breakpoints: {
        768: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        992: {
          slidesPerView: 3,
          spaceBetween: 40,
        },
        1200: {
          slidesPerView: 3,
          spaceBetween: 60,
        },
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.org--partner-slider__nav-icon.swiper-button-next',
        prevEl: '.org--partner-slider__nav-icon.swiper-button-prev',
      },
      loop: true,
    });

    window.addEventListener('resize', () => this.partnerSlider.update());

    window.setTimeout(() => {
      this.initDuplicateSliders();
    }, 1000);
  }

  // init hover-effect on duplicate sliders
  initDuplicateSliders() {
    const duplicateSliders = this.swiperContainer.querySelectorAll('.swiper-slide-duplicate');
    duplicateSliders.forEach((slider) => {
      const partnerTeaserItem = slider.querySelector('.mol--partner-teaser-item');
      const partnerTeaser = new Systemvison2050PartnerTeaser({ element: partnerTeaserItem });
      partnerTeaser.init();
    });
  }
}

export default PartnerSlider;
