import inView from 'in-view';
import arrayFrom from 'array-from';
import { addClass } from '../../tools/helpers';

class FactsFigures {
  constructor(options = {}) {
    this.element = options.element;
    this.lang = document.querySelector('html').lang === 'de' ? 'de-DE' : 'en-GB';
    this.toggledBefore = false;
    this.itemClass = 'org--facts-figures__item';
  }

  init() {
    // console.log('--- Facts Figures Init ---');
    this.addInViewListener();
  }

  addInViewListener() {
    // console.log('--- In View Listener ---');
    inView('.org--facts-figures__wrapper').on('enter', () => {
      if (!this.toggledBefore) {
        this.toggleAnimation();
        this.startCountUp();
        this.toggledBefore = true;
      }
    });
  }

  toggleAnimation() {
    const countUpItems = arrayFrom(this.element.querySelectorAll(`.${this.itemClass}__figure`));
    countUpItems.forEach((countUpItem) => {
      addClass(countUpItem, 'animated');
    });
  }

  startCountUp() {
    // console.log('--- Starting Count up ---');
    const countUpItems = arrayFrom(this.element.querySelectorAll(`.${this.itemClass}__figure`));
    countUpItems.forEach((countUpItem) => {
      const value = countUpItem.querySelector(`.${this.itemClass}__value`);
      value.style.minWidth = `${value.getBoundingClientRect().width}px`;
      // eslint-disable-next-line max-len
      this.countUpAnimation(value, value.dataset.startValue ? Number(value.dataset.startValue) : 0, Number(value.dataset.endValue), value.dataset.duration);
    });
  }

  countUpAnimation(obj, start, end, duration) {
    let startTimestamp = null;
    const step = (timestamp) => {
      if (!startTimestamp) {
        startTimestamp = timestamp;
      }

      // Toggle Visibility for Value if certain Duration is done.
      if (Math.abs(startTimestamp - timestamp) > Number(duration) / 4) {
        addClass(obj, 'visible');
      }

      const progress = Math.min((timestamp - startTimestamp) / Number(duration), 1);

      let value = null;
      // Check with modulus if there is a fraction-value.
      if (end % 1 !== 0) {
        value = (progress * (end - start)) + start;
        const target = obj;
        target.innerHTML = new Intl.NumberFormat(this.lang, { maximumFractionDigits: 1 }).format(value);
      } else {
        value = Math.floor((progress * (end - start)) + start);
        const target = obj;
        target.innerHTML = new Intl.NumberFormat(this.lang).format(value);
      }

      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };

    window.requestAnimationFrame(step);
  }
}

export default FactsFigures;
