import moment from 'moment';
import arrayFrom from 'array-from';

import GridDataGraph from './gridDataGraph';
import GridDataTable from './gridDataTable';
import { addClass, findParent } from '../../../tools/helpers';

class GridDataView {
  constructor(options = {}) {
    this.element = options.element;
    this.data = options.data;
    this.display = options.display;
    this.date = options.date;
    this.type = options.type;
    this.config = options.config;
    this.viewOptions = options.viewOptions;
    this.index = options.index;
  }

  /**
   * Basic Init function.
   * Find and set the Date-displaying Items and hide them if they are not necessary.
   * And add a Listener for the Change-Date-Event.
   * After this check for View Options and render Graph or Table.
   */
  init() {
    this.currentDayDisplay = this.element.querySelector(`.${this.viewOptions.baseClass}__day--display`);
    this.currentMonthDisplay = this.element.querySelector(`.${this.viewOptions.baseClass}__month--display`);
    this.currentYearDisplay = this.element.querySelector(`.${this.viewOptions.baseClass}__year--display`);
    this.prevDayButton = this.element.querySelector(`.${this.viewOptions.baseClass}__day--previous`);
    this.nextDayButton = this.element.querySelector(`.${this.viewOptions.baseClass}__day--next`);
    this.prevMonthButton = this.element.querySelector(`.${this.viewOptions.baseClass}__month--previous`);
    this.nextMonthButton = this.element.querySelector(`.${this.viewOptions.baseClass}__month--next`);
    this.prevYearButton = this.element.querySelector(`.${this.viewOptions.baseClass}__year--previous`);
    this.nextYearButton = this.element.querySelector(`.${this.viewOptions.baseClass}__year--next`);

    if (this.display === 'day') {
      this.hideDay();
    }

    if (this.display === 'month') {
      this.hideDay();
      this.hideMonth();
    }

    this.changeDate();

    window.addEventListener(`row-${this.index}-date-changed`, (ev) => {
      this.date = ev.detail.date;
      this.changeDate();
    });

    if (this.viewOptions.showTable.toString() === 'true') {
      this.renderTable();
    }

    if (this.viewOptions.showGraph.toString() === 'true') {
      this.renderGraph();
    }
  }

  /**
   * Change and Toggle the Display of certain Elements of Date-Display.
   */
  changeDate() {
    this.dateNavButtons = this.element.querySelectorAll('[data-date-nav-type]');
    arrayFrom(this.dateNavButtons).forEach((button) => {
      button.removeAttribute('disabled');
    });

    if (!moment(this.date).subtract(1, 'd').isBetween(moment(this.config.first, 'DD.MM.YYYY'), moment(this.config.latest, 'DD.MM.YYYY'), null, '[]')) {
      this.prevDayButton.setAttribute('disabled', true);
    }

    if (!moment(this.date).add(1, 'd').isBetween(moment(this.config.first, 'DD.MM.YYYY'), moment(this.config.latest, 'DD.MM.YYYY'), null, '[]')) {
      this.nextDayButton.setAttribute('disabled', true);
    }

    if (!moment(this.date).subtract(1, 'M').isBetween(moment(this.config.first, 'DD.MM.YYYY'), moment(this.config.latest, 'DD.MM.YYYY'), null, '[]')) {
      this.prevMonthButton.setAttribute('disabled', true);
    }

    if (!moment(this.date).add(1, 'M').isBetween(moment(this.config.first, 'DD.MM.YYYY'), moment(this.config.latest, 'DD.MM.YYYY'), null, '[]')) {
      this.nextMonthButton.setAttribute('disabled', true);
    }

    if (!moment(this.date).subtract(1, 'y').isBetween(moment(this.config.first, 'DD.MM.YYYY'), moment(this.config.latest, 'DD.MM.YYYY'), null, '[]')) {
      this.prevYearButton.setAttribute('disabled', true);
    }

    if (!moment(this.date).add(1, 'y').isBetween(moment(this.config.first, 'DD.MM.YYYY'), moment(this.config.latest, 'DD.MM.YYYY'), null, '[]')) {
      this.nextYearButton.setAttribute('disabled', true);
    }

    this.currentDayDisplay.innerHTML = moment(this.date).format('DD');
    this.currentMonthDisplay.innerHTML = moment(this.date).format('MM');
    this.currentYearDisplay.innerHTML = moment(this.date).format('YYYY');
  }

  /**
   * Hide the Day-Display.
   */
  hideDay() {
    this.prevDayButton.setAttribute('disabled', true);
    this.nextDayButton.setAttribute('disabled', true);
    addClass(findParent(this.currentDayDisplay, `${this.viewOptions.baseClass}__day`), 'hidden');
  }

  /**
   * Hide the Month-Display.
   */
  hideMonth() {
    this.prevMonthButton.setAttribute('disabled', true);
    this.nextMonthButton.setAttribute('disabled', true);
    addClass(findParent(this.currentMonthDisplay, `${this.viewOptions.baseClass}__month`), 'hidden');
  }

  /**
   * Init the Table Class and init it.
   */
  renderTable() {
    const gridDataTable = new GridDataTable({
      element: this.element,
      type: this.type,
      display: this.display,
      items: this.data.items,
      data: this.data,
      config: this.config,
      viewOptions: this.viewOptions,
    });

    gridDataTable.init();
  }

  /**
   * Init the Graph Class and init it.
   */
  renderGraph() {
    const gridDataGraph = new GridDataGraph({
      element: this.element,
      type: this.type,
      display: this.display,
      items: this.data.items,
      data: this.data,
      config: this.config,
      viewOptions: this.viewOptions,
    });

    gridDataGraph.init();
  }
}

export default GridDataView;
