import arrayFrom from 'array-from';

import ScrollHandler from '../ScrollHandler';
import { removeClass, addClass } from '../../tools/helpers';

export const CLASSNAME_ENTRIES = 'org--glossary__body__entry';

export const getAbsoluteHeight = (el) => {
  const element = (typeof el === 'string') ? document.querySelector(el) : el;
  let height = 0;

  arrayFrom(element.children).forEach((child) => {
    const childStyles = window.getComputedStyle(child);
    height += parseInt(childStyles.marginBottom, 10);

    arrayFrom(child.children).forEach((grandChild) => {
      const grandChildStyles = window.getComputedStyle(grandChild);
      height += parseInt(grandChildStyles.marginBottom, 10);
      height += grandChild.offsetHeight;
    });

  });

  return height;
};

class Glossary {
  constructor(options = {}) {
    this.element = options.element;
    this.entries = this.element.querySelectorAll(`.${CLASSNAME_ENTRIES}`);
    this.buttonsWrapper = this.element.querySelector('.org--glossary__navigation__holder');
    this.entryMeasures = [];
    this.scrollHandler = new ScrollHandler();
  }

  /**
   * Initing and checking for Hash-Change or Hash-Preset.
   */
  init() {
    if (window.location.hash) {
      window.setTimeout(() => {
        window.scrollTo(0, 0);
        this.scrollToTarget(this.element.querySelector('.org--glossary__body__holder'), this.element.querySelector(window.location.hash), 0);
      }, 500);
    }

    window.addEventListener('hashchange', (event) => {
      event.preventDefault();
      window.scrollTo(0, 0);
      this.scrollToTarget(this.element.querySelector('.org--glossary__body__holder'), this.element.querySelector(window.location.hash), 0);
    });

    this.indexThroughEntries();
  }

  /**
   * Generate default Alphabet and check if there are non-alphabetical Characters.
   * Also Replace Umlauts and convert to lower Case.
   */
  indexThroughEntries() {
    const activeEntries = [...new Set(arrayFrom(this.entries).map(entry => entry.querySelector('h2').innerText[0]
      .toLowerCase()
      .replace(/\u00e4/g, 'a')
      .replace(/\u00fc/g, 'u')
      .replace(/\u00f6/g, 'o')))];

    const defaultEntries = [...Array(26)].map((val, i) => String.fromCharCode(i + 65).toLowerCase());

    if (activeEntries.some(entry => !defaultEntries.includes(entry))) {
      activeEntries.push('symbol');
      defaultEntries.push('symbol');
    }

    this.prepareButtons(activeEntries, defaultEntries);
  }

  /**
   * Preparing the Buttons. Disabling them, if there are no active Entries for this letter.
   * Afterwards preparing the letter Observer for each entry.
   * @param {*} activeEntries - the active entries
   * @param {*} defaultEntries - the default entries generated by Char-Array.
   */
  prepareButtons(activeEntries, defaultEntries) {
    // console.log(activeEntries);
    defaultEntries.forEach((entry) => {
      const button = document.createElement('button');
      button.dataset.href = `glossary-entry-${entry}`;

      if (entry === 'symbol') {
        button.innerText = '~';
      } else {
        button.innerText = entry.toUpperCase();
      }

      if (!activeEntries.includes(entry)) {
        button.disabled = true;
      }

      // Temporarily disabling Letter Observer. Keeping it for future Purpose
      // if (this.element.querySelector(`#${button.dataset.href}`) !== null) {
      //   this.prepareLetterObserver(button);
      // }

      this.addUsability(button);
      this.buttonsWrapper.appendChild(button);
    });
  }

  /**
   * Make Buttons clickable and make them jump to the right section.
   * @param {*} item
   */
  addUsability(item) {
    const listener = item.dataset.href;

    if (listener !== null) {
      item.addEventListener('click', (event) => {
        event.preventDefault();
        this.scrollToTarget(this.element.querySelector('.org--glossary__body__holder'), this.element.querySelector(`#${listener}`), 0);
      });
    }
  }

  /**
   * Get all sections that are wrapped inside a ".glossary-entry-"-div and push their measurements to an object.
   * Afterwards add ScrollListener
   */
  prepareLetterObserver(item) {
    const entryItem = this.element.querySelector(`#${item.dataset.href}`);

    const bodyHeight = this.element.querySelector('.org--glossary__body__holder').getBoundingClientRect().height;
    let scrollTop = this.element.querySelector('.org--glossary__body__holder').scrollTop;

    window.setTimeout(() => {
      const absoluteHeight = getAbsoluteHeight(entryItem);

      this.entryMeasures.push({
        element: entryItem,
        id: entryItem.id,
        offsetTop: entryItem.offsetTop,
        computedHeight: absoluteHeight,
        bottom: entryItem.offsetTop + entryItem.offsetHeight,
      });

      this.checkForVisibility(scrollTop, bodyHeight);
    }, 300);

    this.element.querySelector('.org--glossary__body__holder').addEventListener('scroll', (event) => {
      event.preventDefault();
      scrollTop = event.target.scrollTop;
      const visibleArea = this.element.querySelector('.org--glossary__body__holder').scrollTop + bodyHeight;
      this.checkForVisibility(scrollTop, visibleArea);
    });
  }

  /**
   * Check if a Section is visible by checking for the scrolled Amount and the visible Area.
   * @param {*} scrollTop - Number of scrolled Pixels from top.
   * @param {*} visibleArea - Number for the visible Area.
   */
  checkForVisibility(scrollTop, visibleArea) {
    this.entryMeasures.forEach((entry) => {
      if (entry.offsetTop <= visibleArea && entry.bottom >= scrollTop) {
        this.addButtonActive(this.buttonsWrapper.querySelector(`[data-href="${entry.id}"]`));
      } else if (entry.offsetTop <= scrollTop && (entry.offsetTop + entry.computedHeight) <= scrollTop) {
        this.removeButtonActive(this.buttonsWrapper.querySelector(`[data-href="${entry.id}"]`));
      } else if (entry.offsetTop > visibleArea) {
        this.removeButtonActive(this.buttonsWrapper.querySelector(`[data-href="${entry.id}"]`));
      }
    });
  }

  scrollToTarget(container, target, offset = 0) {
    window.setTimeout(() => {
      this.scrollHandler.scrollToTarget(container, target, offset);
    }, 500);
  }

  addButtonActive(item) {
    addClass(item, 'is-active');
  }

  removeButtonActive(item) {
    removeClass(item, 'is-active');
  }
}

export default Glossary;
