import ConsentVideo from '../ConsentVideo';

class ConsentIFrame extends ConsentVideo {
  constructor(options = {}) {
    super(options);
    this.element = options.element;
    this.iframeId = this.element.dataset.iframeId;
  }

  insertContent() {
    super.insertContent();

    document.getElementById(this.iframeId).addEventListener('load', () => {
      // eslint-disable-next-line
      iFrameResize({ log: false }, `#${this.iframeId}`);
    });
  }
}

export default ConsentIFrame;
