import Swiper from 'swiper/bundle';

class ProjectsSlider {
  constructor(options = {}) {
    this.element = options.element;
    this.swiperContainer = this.element.querySelector('.swiper-container');
  }

  init() {
    this.projectSlider = new Swiper(this.swiperContainer, {
      slidesPerView: 1,
      breakpoints: {
        768: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        992: {
          slidesPerView: 2,
          spaceBetween: 40,
        },
        1200: {
          slidesPerView: 2,
          spaceBetween: 60,
        },
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.org--projects-slider__nav-icon.swiper-button-next',
        prevEl: '.org--projects-slider__nav-icon.swiper-button-prev',
      },
      loop: true,
    });

    window.addEventListener('resize', () => this.projectSlider.update());
  }

}

export default ProjectsSlider;
