import arrayFrom from 'array-from';
import clamp from 'text-overflow-clamp';
import FontFaceObserver from 'fontfaceobserver';

import { CONFIG } from '../config';
import getEventHandlerResize from '../ResizeHandler';

/**
 *
 * @param {*} currentViewport: Current Viewport emitted from ResizeHandler.
 * Could be any given string from CONFIG.viewports.
 */
const viewportSwitch = (currentViewport) => {
  switch (currentViewport) {
    case 'XS':
    case 'MS':
    case 'SM':
    case 'MD':
      return false;
    default:
      return true;
  }
};

class TeaserGroup {
  constructor(options = {}) {
    this.element = options.element;
    this.maxLinesHeadline = 3;
    this.maxLinesTeaserText = 7;
    this.fontHasLoaded = false;
    this.hasClamped = false;
  }

  init() {
    this.checkForClamping();
    // Trigger Clamping Check on window.resize event (CONFIG.events.amp-event-resize)
    window.addEventListener(CONFIG.events.viewportChange, () => {
      this.checkForClamping();
    });
  }

  /**
   * Check if Clamping is necessary, depends on viewport
   */
  checkForClamping() {
    if (viewportSwitch(getEventHandlerResize().getCurrentViewport())) {
      if (!this.fontHasLoaded) {
        const font = new FontFaceObserver('Gotham');

        // Timeout is necessary because of font-display: swap.
        // Otherwise the width gets calculated incorrectly.
        font.load().then(() => {
          window.setTimeout(() => {
            this.fontHasLoaded = true;
            this.initClamps();
          }, 200);
        }, () => {
          console.error(`Font ${font} is not available`);
        });
      } else if (this.hasClamped === false) {
        this.initClamps();
      }

      // Check if items have been clamped before and viewport is not mobile or tablet
      // (Since Mobile and Tablet Viewports need no clamping).
    } else if (this.hasClamped && viewportSwitch(getEventHandlerResize().getCurrentViewport()) === false) {
      this.restoreContent();
    }
  }

  /**
   * Clamping Function:
   * - Fetches all headlines (h3) and paragraphs of the items.
   * – Checks if the Height/Line-height-quota exceeds the count on maxLines.
   * – Sets items depending on striped span inside or not.
   * – Sets this.hasClamped to true, to trigger the Function only once.
   */
  initClamps() {
    this.headlines = arrayFrom(this.element.querySelectorAll('h3'));
    this.headlines.forEach((headline) => {
      const heightDiff = headline.offsetHeight / parseInt(document.defaultView.getComputedStyle(headline, null).getPropertyValue('line-height'), 10);
      if (heightDiff > this.maxLinesHeadline) {
        if (headline.querySelector('span[class*=stripe]')) {
          clamp(headline.querySelector('span[class*=stripe]'), this.maxLinesHeadline);
        } else {
          clamp(headline, this.maxLinesHeadline);
        }
      }
    });

    this.teaserTextItems = arrayFrom(this.element.querySelectorAll('p'));
    this.teaserTextItems.forEach((teaserTextItem) => {
      const heightDiff = teaserTextItem.offsetHeight / parseInt(document.defaultView.getComputedStyle(teaserTextItem, null).getPropertyValue('line-height'), 10);
      if (heightDiff > this.maxLinesTeaserText) {
        clamp(teaserTextItem, this.maxLinesTeaserText);
      }
    });

    this.hasClamped = true;
  }

  /**
   * Restores the content by resetting innerHTML to innerText and getting rid of the spans.
   * Be aware of the stripes!
   */
  restoreContent() {
    this.headlines = arrayFrom(this.element.querySelectorAll('h3'));
    this.teaserTextItems = arrayFrom(this.element.querySelectorAll('p'));

    this.headlines.forEach((headline) => {
      const h = headline;
      if (h.querySelector('span[class*=stripe]')) {
        h.querySelector('span[class*=stripe]').innerHTML = h.innerText;
      } else {
        h.innerHTML = h.innerText;
      }
    });

    this.teaserTextItems = arrayFrom(this.element.querySelectorAll('p'));
    this.teaserTextItems.forEach((teaserTextItem) => {
      const ttItem = teaserTextItem;
      ttItem.innerHTML = ttItem.innerText;
    });

    this.hasClamped = false;
  }
}

export default TeaserGroup;
