import { addClass, removeClass } from '../../tools/helpers';
import ScrollHandler from '../ScrollHandler';

export const CLASSNAME_AFFIX = 'affix';
export const CLASSNAME_AFFIX_TOP = 'affix-top';

class ToTopButton {
  constructor(options = {}) {
    this.element = options.element;
  }

  init() {
    this.addScrollHandler();
    let scrollPosition = window.pageYOffset;

    window.addEventListener('scroll', (event) => {
      try {
        scrollPosition = event.target.scrollingElement.scrollTop;
      } catch (error) {
        scrollPosition = 1;
      }

      if (scrollPosition > 0) {
        addClass(this.element, `${CLASSNAME_AFFIX}`);
        removeClass(this.element, `${CLASSNAME_AFFIX_TOP}`);
      } else {
        addClass(this.element, `${CLASSNAME_AFFIX_TOP}`);
        removeClass(this.element, `${CLASSNAME_AFFIX}`);
      }
    });
  }

  addScrollHandler() {
    const scrollHandler = new ScrollHandler();

    this.element.addEventListener('click', (ev) => {
      ev.preventDefault();
      scrollHandler.scrollToTopOfPage(window, true);
    });
  }
}

export default ToTopButton;
