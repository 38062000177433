import SearchView from '../View';

class SearchController {
  constructor(options = {}) {
    this.element = options.element;
    this.params = options.params;
  }

  init() {
  }

  add() {
    this.addView();
  }

  update() {
    this.fetch();
  }

  addView() {
    this.view = new SearchView({
      element: this.element,
      params: this.params,
    });
  }

  fetch() {
    this.view.showSpinner();
    this.setParams(this.params);
    const url = `${this.params.url}?q=${this.params.query}&lang=${this.params.lang}&page=${this.params.currentPage}`;
    fetch(url)
      .then(res => res.json())
      .then((query) => {
        this.view.results = query.results;
        this.view.params.lang = this.params.lang;
        this.view.params.query = this.params.query;
        this.view.params.currentPage = Number(this.params.currentPage);
        this.view.params.totalHits = query.totalHits;

        this.view.showCount();
        this.view.hideSpinner();
        this.view.hideServerError();
        this.view.hideNoResultsError();
        this.view.update();
      })
      .catch((er) => {
        this.view.clear();
        this.view.hideCount();
        this.view.hideSpinner();
        this.view.hideNoResultsError();
        this.view.showServerError();
        console.error(er);
      });
  }

  setParams(params) {
    // eslint-disable-next-line
    if (history.pushState) {
      const newParams = new URLSearchParams(window.location.search);
      newParams.set('lang', params.lang);
      newParams.set('query', params.query);
      newParams.set('currentPage', params.currentPage);
      const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${newParams.toString()}`;
      window.history.pushState({ path: newUrl }, '', newUrl);
    }
  }
}

export default SearchController;
