class StartSiteTicker {

  constructor(options = {}) {
    this.element = options.element;
    if (this.element.querySelector('a')) {
      this.link = this.element.querySelector('a').getAttribute('href');
      this.target = this.element.querySelector('a').getAttribute('target') || '_self';
    }
  }

  init() {
    if (this.link) {
      this.element.style.cursor = 'pointer';
      this.element.addEventListener('click', (ev) => {
        ev.preventDefault();
        window.open(this.link, this.target);
      });
    }
  }
}

export default StartSiteTicker;
