import { addClass, removeClass, findParent } from '../../tools/helpers';
import ScrollHandler from '../ScrollHandler';

export const CLASSNAME_AFFIX = 'affix';
export const CLASSNAME_AFFIX_TOP = 'affix-top';

class StickyText {
  constructor(options = {}) {
    this.element = options.element;
    this.offset = this.element.dataset.offsetTop || null;
  }

  init() {
    this.addScrollHandler();

    let scrollPosition = window.pageYOffset;

    if (scrollPosition !== null && scrollPosition >= this.offset) {
      addClass(findParent(this.element, 'button-sticky__wrapper'), `${CLASSNAME_AFFIX}`);
      removeClass(findParent(this.element, 'button-sticky__wrapper'), `${CLASSNAME_AFFIX_TOP}`);
    } else if (scrollPosition !== null && scrollPosition <= this.offset) {
      addClass(findParent(this.element, 'button-sticky__wrapper'), `${CLASSNAME_AFFIX_TOP}`);
      removeClass(findParent(this.element, 'button-sticky__wrapper'), `${CLASSNAME_AFFIX}`);
    }

    window.addEventListener('scroll', (event) => {
      scrollPosition = event.target.scrollingElement.scrollTop;

      if (scrollPosition !== null && scrollPosition >= this.offset) {
        addClass(findParent(this.element, 'button-sticky__wrapper'), `${CLASSNAME_AFFIX}`);
        removeClass(findParent(this.element, 'button-sticky__wrapper'), `${CLASSNAME_AFFIX_TOP}`);
      } else if (scrollPosition !== null && scrollPosition <= this.offset) {
        addClass(findParent(this.element, 'button-sticky__wrapper'), `${CLASSNAME_AFFIX_TOP}`);
        removeClass(findParent(this.element, 'button-sticky__wrapper'), `${CLASSNAME_AFFIX}`);
      }
    });

  }

  addScrollHandler() {
    const target = document.querySelector(this.element.querySelector('a').getAttribute('href'));
    const offset = document.querySelector('header').getBoundingClientRect().height;
    const scrollHandler = new ScrollHandler();
    let computedOffset = null;

    this.element.querySelector('a').addEventListener('click', (ev) => {
      ev.preventDefault();
      if (window.pageYOffset <= parseInt(this.offset, 10)) {
        computedOffset = offset * 2;
      } else {
        computedOffset = offset;
      }

      scrollHandler.scrollContainerToTarget(window, target, computedOffset + 40, true);
    });
  }
}

export default StickyText;
