
class iFrameHandler {
  constructor(options = {}) {
    this.element = options.element;
    this.iFrame = this.element.querySelector('iframe');
    this.iframeContent = this.iFrame.contentDocument;
  }

  init() {

    window.setTimeout(() => this.resizeIframe(), 1000);
    // resize second time, if first time not all for iframe styles are applied
    window.setTimeout(() => this.resizeIframe(), 3000);

    window.addEventListener('resize', () => {
      this.resizeIframe();
    });
  }

  resizeIframe() {
    const iFrame = this.element.querySelector('iframe');
    const iframeContent = iFrame.contentDocument;
    if (iframeContent) {
      const body = iframeContent.querySelector('body');
      const contentHeight = body.offsetHeight;
      this.iFrame.style.height = `${contentHeight + 30}px`;
    }
  }
}
export default iFrameHandler;
