import ContentHubDefaultSlider from '../ContentHubDefaultSlider';

class ContentHubDefaultGallery {
  constructor(options = {}) {
    this.element = options.element;
    this.enableSlider = true;
    this.showArrows = this.element.dataset.showArrows || false;
    this.showBullets = this.element.dataset.showBullets || false;
    this.showLightbox = this.element.dataset.showLightbox || false;
    this.showThumbs = this.element.dataset.thumbs || false;
    this.imageClick = this.element.dataset.imageClick || false;
    this.galleryId = this.element.dataset.id || 0;
  }

  init() {
    if (this.enableSlider) {
      this.initSlider();
    }
  }

  initSlider() {
    const slider = new ContentHubDefaultSlider({
      element: this.element,
      wrapper: this.element.querySelector(`[id="gallery-${this.galleryId}"]`),
      showArrows: this.showArrows,
      showBullets: this.showBullets,
      showLightBox: this.showLightbox,
      showThumbs: this.showThumbs,
      imageClick: this.imageClick,
      itemClass: 'org--carousel__media__item',
      trackTabbable: true,
      galleryId: this.galleryId,
      slidesPerView: 1.5,
      slidesPerViewMobil: 1.1,
      slidesPerViewTablet: 1.3,
      watchSlidesProgress: true,
      autoplay: false,
      options: {
        autoHeight: true,
        /* autoplay: {
          delay: 7000,
          disableOnInteraction: false,
        }, */
        gap: 0,
        loop: true,
        loopAdditionalSlides: 0,
      },
    });
    slider.prepareDom();
    if (this.showThumbs) {
      slider.prepareThumbs();
    }
  }
}

export default ContentHubDefaultGallery;
