import ModalLayer from '../ModalLayer';
import TemplateHandler from '../TemplateHandler';

class ZoomImage {

  constructor(options = {}) {
    this.element = options.element;
    this.zoomBoxId = this.element.dataset.zoomBoxId;
    this.zoomImageContent = TemplateHandler.getTemplate('zoom-box-content', this.element);
    this.indicatorImage = this.element.querySelector('.indicator-image') || null;
  }

  init() {
    this.showZoomImage();

    // prevent the opening of the zoom image when clicking on a link
    if (this.indicatorImage) {
      this.indicatorImage.addEventListener('click', (event) => {
        event.stopPropagation(); // this line prevents the link click from bubbling
      });
    }
  }

  showZoomImage() {
    this.element.addEventListener('click', () => this.handleAction());
    this.element.addEventListener('keydown', (event) => {
      if (event.key === 'Enter') {
        this.handleAction();
      }
    });
  }

  handleAction() {
    this.zoomImagebox = new ModalLayer({
      element: this.element,
      content: document.querySelector(`[id="modal-layer-${this.zoomBoxId}"]`),
    });
    this.zoomImagebox.bindEvents();
    this.insertContent();
  }

  insertContent() {
    const content = document.createElement('section');
    content.insertAdjacentHTML('beforeend', this.zoomImageContent);
    this.zoomImagebox.replaceContent(content);
    this.zoomImagebox.showModal();
  }

}

export default ZoomImage;
