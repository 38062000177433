import 'url-search-params-polyfill';

import SearchController from './Controller';

const EVENTNAME_QUERY_CHANGE = 'search-query-changed';

class Search {
  constructor(options = {}) {
    this.element = options.element;
    this.params = {};
    this.searchParams = new URLSearchParams(window.location.search);
    this.params.query = this.searchParams.get('query') || null;
    this.params.lang = this.searchParams.get('lang') || null;
    this.params.currentPage = this.searchParams.get('currentPage') || 0;
    this.baseUrl = window.location.origin;
    this.params.url = `${this.baseUrl}/api/search`;
  }

  init() {
    this.addNewController();
    this.addParams();
    this.addListener();
  }

  addNewController() {
    this.controller = new SearchController({
      element: this.element,
      params: this.params,
    });

    this.controller.add();
  }

  addParams() {
    if (this.params.query !== null && this.params.lang !== null) {
      this.controller.params.lang = this.params.lang;
      this.controller.params.query = this.params.query;
      this.controller.params.currentPage = this.params.currentPage;
      this.controller.update();
    }
  }

  addListener() {
    window.onpopstate = () => {
      const newParams = new URLSearchParams(window.location.search);
      this.controller.params.lang = newParams.get('lang');
      this.controller.params.query = newParams.get('query');
      this.controller.params.currentPage = newParams.get('currentPage');
      this.controller.update();
    };

    window.addEventListener(EVENTNAME_QUERY_CHANGE, (ev) => {
      this.controller.params.lang = ev.detail.params.lang;
      this.controller.params.query = ev.detail.params.query;
      this.controller.params.currentPage = this.params.currentPage;
      this.controller.update();
    });
  }
}

export default Search;
