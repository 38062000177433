class JobTeaserApi {
  constructor(options = {}) {
    this.element = options.element;
    this.fallbackNumber = this.element.dataset.fallbackNumber;
    this.numberOfJobsApiUrl = this.element.dataset.apiUrl;
    this.numberContaineer = this.element.querySelector('.org--career-job-teaser__numbers');
  }

  init() {
    this.getTotalJobsNumbers();
  }

  getTotalJobsNumbers() {
    fetch(this.numberOfJobsApiUrl, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    })
      .then(response => response.json())
      .then((data) => {
        if (data) {
          const totalNumber = data.jobCount || this.fallbackNumber;
          this.splitNumber(totalNumber);
        } else {
          this.splitNumber(this.fallbackNumber);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        this.splitNumber(this.fallbackNumber);
      });
  }

  splitNumber(totalNumber) {
    this.jobNumber = totalNumber.toString();
    const jobNumberArr = this.jobNumber.split('');
    const numberDiv = jobNumberArr.map(number => `<div class="org--career-job-teaser__number">${number}</div>`);
    this.numberContaineer.insertAdjacentHTML('afterbegin', numberDiv.join(''));
  }
}

export default JobTeaserApi;
