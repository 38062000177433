import * as d3 from 'd3';
import arrayFrom from 'array-from';
import { addClass, removeClass } from '../../../tools/helpers';

/**
 * Parse Date function gives back the parsed d3-time.
 */
const parseDate = d3.timeParse('%d.%m.%Y %I:%M');

let summerTime = {};
/**
 * Adding Language-depending Labels to the d3-locale.
 * @param {*} lang - the Lang Attribute set inside the constructor.
 */
const rangeDependingLabels = (lang) => {
  let langReturn = null;
  switch (lang) {
    case 'de':
    default:
      langReturn = {
        decimal: ',',
        thousands: '.',
        grouping: [3],
        currency: ['€', ''],
        dateTime: '%a %b %e %X %Y',
        date: '%d.%m.%Y',
        time: '%H:%M:%S',
        periods: ['AM', 'PM'],
        days: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
        shortDays: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
        months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
        shortMonths: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
      };
      break;
    case 'en':
      langReturn = {
        decimal: '.',
        thousands: ',',
        grouping: [3],
        currency: ['$', ''],
        dateTime: '%a %b %e %X %Y',
        date: '%m/%d/%Y',
        time: '%H:%M:%S',
        periods: ['AM', 'PM'],
        days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      };
      break;
  }

  return langReturn;
};

/**
 * Set display-specific Ticks
 * @param {*} display - the display added to the module.
 */
const formatSpecificTicks = (display) => {
  let tickFormats = null;
  switch (display) {
    case 'quarterhour':
    default:
      tickFormats = {
        tickCountX: 10,
        tickCountY: 0,
        timeFormat: '%H:%M',
      };
      break;
    case 'day':
      tickFormats = {
        tickCountX: 10,
        tickCountY: 0,
        timeFormat: '%e',
      };
      break;
    case 'month':
      tickFormats = {
        tickCountX: 10,
        tickCountY: 0,
        timeFormat: '%b',
      };
  }

  return tickFormats;
};

class GridDataGraph {
  constructor(options = {}) {
    this.element = options.element;
    this.items = options.items;
    this.display = options.display;
    this.config = options.config;
    this.viewOptions = options.viewOptions;
    this.graph = this.element.querySelector(`.${this.viewOptions.baseClass}__graph`);
    this.dataset = [];
    this.lang = document.querySelector('html').lang;
    this.langCode = this.lang === 'de' ? 'de-DE' : 'en-IN';
    d3.formatDefaultLocale(rangeDependingLabels(this.lang));
    d3.timeFormatDefaultLocale(rangeDependingLabels(this.lang));

    this.graphOptions = {
      initialHeight: 500,
      margins: {
        top: 20,
        right: 50,
        bottom: 70,
        left: 50,
      },
      colors: {
        0: '#2268b1', // Static HEX Values for the Colours. Maybe find a dynamic solution in the future.
        1: '#9e007e',
      },
    };
  }

  /**
   * Initing the Graph Display.
   * Defining width and height by using margins
   */
  init() {
    // Width and height of SVG
    // eslint-disable-next-line max-len
    this.width = this.element.getBoundingClientRect().width - this.graphOptions.margins.left - this.graphOptions.margins.right;
    this.height = this.graphOptions.initialHeight - this.graphOptions.margins.bottom - this.graphOptions.margins.top;

    // Clearing it as a workaround for Updating the Lines and Legends.
    // Need to find a better solution in the future.
    this.graph.innerHTML = '';

    this.svg = d3.select(this.graph).append('svg')
      .attr('width', this.width + this.graphOptions.margins.left + this.graphOptions.margins.right)
      .attr('height', this.height + this.graphOptions.margins.top + this.graphOptions.margins.bottom)
      .append('g')
      .attr('transform', `translate(${this.graphOptions.margins.left},${this.graphOptions.margins.top})`);

    this.fixWinterTimeChange();
    this.formatData();
  }

  /**
   * Workaround for wintertime-change (AMP-1993)
   * d3 can't handle the time change. The x-axis correctly contains
   * both times 2 to 3 o'clock, but the second data values are drawn at
   * the first 2 o'clock appearance resulting in a visual loop.
   */
  fixWinterTimeChange() {
    const winterTime = this.removeDuplicateTimes(this.items.items);

    if (winterTime !== undefined && winterTime.length !== 0) {
      this.items.items = winterTime.concat(summerTime);
    }
  }

  removeDuplicateTimes(arr) {
    const seen = new Set();
    let dateTimeObject;

    for (let i = 0; i < arr.length; i += 1) {
      const dateItem = arr[i].date;
      dateTimeObject = parseDate(dateItem);
      const timestamp = dateTimeObject.getTime();

      // Duplicate timestamp indicating wintertime-change
      if (seen.has(timestamp)) {
        // split array and set the summertime
        summerTime = arr.slice(i, arr.length);
        // remove the first values between 2 to 3 o'clock
        // slice and return elements without the duplicates
        return arr.slice(3, i - 4);
      }

      seen.add(timestamp);
    }

    // No duplicates found, return an empty array
    return [];
  }

  /**
   * Formatting input data for d3-Purposes
   */
  formatData() {
    if (this.items.items.length > 0) {
      this.items.items.forEach((item) => {
        const output = {};
        output.newDate = item.date;
        item.values.forEach((set, i) => {
          output[`set${i}`] = {
            label: this.viewOptions.labels.split(';')[i],
            color: this.graphOptions.colors[i],
            value: item.values[i],
          };
        });
        this.dataset.push(output);
      });
    }

    this.initGraph();
  }

  /**
   * Show and hide Messages
   */
  showMissingDataMessage() {
    this.customErrorMessageData = this.element.dataset.customErrorMessageData || null;
    this.defaultErrorMessageData = this.element.dataset.errorMessageData || null;
    const errorMessageData = this.customErrorMessageData ? this.customErrorMessageData : this.defaultErrorMessageData;
    if (errorMessageData !== null) {
      const errorMessageDataWrapper = this.element.querySelector(`.${this.viewOptions.baseClass}__error--data`);
      errorMessageDataWrapper.innerHTML = errorMessageData;
      removeClass(errorMessageDataWrapper, 'hidden');
      addClass(errorMessageDataWrapper, 'shown');
    }
  }

  hideMissingDataMessage() {
    const errorMessageDataWrapper = this.element.querySelector(`.${this.viewOptions.baseClass}__error--data`);
    addClass(errorMessageDataWrapper, 'hidden');
    removeClass(errorMessageDataWrapper, 'shown');
  }

  /**
   * Setting up the Graph
   * X- and Y-Scales are created depending on min-max-values.
   * If there is an empty Dataset fallback to Error Message.
   */
  initGraph() {
    if (this.dataset.length !== 0) {
      // Defining the x and y.
      this.xScale = d3.scaleTime().range([0, this.width]);
      this.yScale = d3.scaleLinear().range([this.height, 0]);

      this.scale = d3.scaleOrdinal(d3.schemeCategory10);
      this.xAxis = d3.axisBottom(this.xScale).ticks(formatSpecificTicks(this.display).tickCountX)
        .tickFormat(d3.timeFormat(formatSpecificTicks(this.display).timeFormat));
      this.yAxisLeft = d3.axisLeft(this.yScale).ticks(10);
      this.yAxisGrid = d3.axisLeft(this.yScale).ticks(10);
      this.yAxisRight = d3.axisRight(this.yScale).ticks(10);

      this.line = d3.line().x(d => this.xScale(d.date)).y(d => this.yScale(d.value)).defined(d => d.value !== null)
        .curve(d3.curveLinear);

      // Update Graph
      this.updateGraph();
      this.hideMissingDataMessage();
    } else {
      this.showMissingDataMessage();
    }
  }

  /**
   * Update the Graph.
   * This means formatting the data and setting up the Axes, Legends and Grids.
   */
  updateGraph() {
    this.scale.domain(d3.keys(this.dataset[0]).filter(key => key !== 'newDate'));

    // Parse Date for D3.
    // eslint-disable-next-line
    this.dataset.forEach(d => d.newDate = parseDate(d.newDate));

    // Map the Values according to D3.
    // eslint-disable-next-line
    this.sets = this.scale.domain().map((i) => {
      return {
        name: this.dataset[0][i].label,
        color: this.dataset[0][i].color,
        // eslint-disable-next-line
        values: this.dataset.map((d) => {
          return {
            date: d.newDate,
            value: d[i].value,
          };
        }),
      };
    });

    this.xScale.domain(d3.extent(this.dataset, d => d.newDate));

    // Set min and max on the Y-Axis according to the min-max-values of the fetched Config.
    this.yScale.domain([this.config.min, this.config.max]);

    // Setting up the legend.
    this.legend = this.svg.selectAll('g').data(this.sets).enter().append('g')
      .attr('class', 'legend');
    this.legend.append('rect').attr('x', 0).attr('y', (d, i) => this.height + this.graphOptions.margins.bottom - (i * 20) - 20)
      .attr('width', 10)
      .attr('height', 10)
      .style('fill', d => d.color);
    this.legend.append('text').attr('x', 20).attr('y', (d, i) => this.height + this.graphOptions.margins.bottom - (i * 20) - 9).text(d => d.name);

    // Adding the X-Axis.
    this.svg.append('g')
      .attr('class', 'x axis')
      .attr('transform', `translate(0, ${this.height})`)
      .call(this.xAxis);

    // Adding the Grid Lines.
    this.svg.append('g')
      .attr('class', 'grid')
      .call(this.yAxisGrid.tickSize(-this.width).tickFormat(''))
      .style('stroke', 'silvergrey');

    // Adding the left Y-Axis.
    this.svg.append('g')
      .attr('class', 'y axis')
      .style('fill', 'steelblue')
      .call(this.yAxisLeft);

    // Adding the right Y-Axis.
    this.svg.append('g')
      .attr('class', 'y axis')
      .attr('transform', `translate(${this.width}, 0)`)
      .style('fill', 'red')
      .call(this.yAxisRight);

    // Finally adding the Graph Lines.
    const set = this.svg.selectAll('.set')
      .data(this.sets)
      .enter()
      .append('g')
      .attr('class', 'set');

    set.append('path')
      .attr('class', 'line')
      .attr('d', (d) => {
        const values = this.viewOptions.reverseOrder === 'true' ? d.values.reverse() : d.values;
        return this.line(values);
      })
      .style('stroke', d => d.color);

    this.initFunctionality();
  }

  updateYAxis(newMin, newMax) {
    // Update the yScale domain based on the new min and max values
    this.yScale.domain([newMin, newMax]);

    // Update the Y-Axis if it exists
    this.svg.select('.y.axis')
      .transition()
      .duration(750)
      .call(this.yAxisRight.scale(this.yScale));

    // Update the grid lines
    this.svg.select('.grid')
      .transition()
      .duration(750)
      .call(this.yAxisGrid.scale(this.yScale).tickSize(-this.width).tickFormat(''));

    // Update the line paths
    this.svg.selectAll('.line')
      .transition()
      .duration(750)
      .attr('d', d => this.line(d.values));
  }

  /**
   * Add functionality for User Input.
   */
  initFunctionality() {
    const cursorOverTrigger = this.svg.append('g').attr('class', 'cursor-over-effects');
    cursorOverTrigger.append('line') // this is the black vertical line to follow the cursor.
      .attr('class', 'cursor-vertical-line')
      .attr('x1', 0)
      .attr('y1', 0)
      .attr('x2', 0)
      .attr('y2', this.height)
      .style('stroke', 'black')
      .style('stroke-width', '1px')
      .style('opacity', '0');

    // Append the Tooltip.
    d3.select(this.graph).append('div')
      .attr('class', 'graph-tooltip')
      .style('transform', 'translate(100%, 50%)');

    // Append Spans for Labels, Legends and Values.
    this.sets.forEach((set, index) => {
      d3.select(this.element.querySelector('.graph-tooltip')).append('span').attr('class', `span-set-${index}`).style('display', 'block');
    });

    // Globally declare lines.
    this.lines = this.element.getElementsByClassName('line');

    // Prepare the indicatorCircles
    const indicatorCircle = this.svg.selectAll('.cursor-per-line')
      .data(this.sets)
      .enter()
      .append('g')
      .attr('class', 'cursor-per-line');

    indicatorCircle.append('circle')
      .attr('r', 3)
      .style('stroke', d => d.color)
      .style('fill', 'none')
      .style('stroke-width', '1px') // Outsource the Styling for further purposes.
      .style('opacity', '0');

    indicatorCircle.append('text')
      .attr('transform', 'translate(10, 3)');


    cursorOverTrigger.append('svg:rect') // append a rect to catch cursor movements on canvas
      .attr('width', this.width) // can't catch cursor events on a g element
      .attr('height', this.height)
      .attr('fill', 'none')
      .attr('pointer-events', 'all')
      .on('mouseout', () => { // on cursor out hide line, circles and text
        d3.select(this.element.querySelector('.cursor-vertical-line'))
          .style('opacity', '0');
        d3.selectAll('.cursor-per-line circle')
          .style('opacity', '0');
        d3.selectAll('.cursor-per-line text')
          .style('opacity', '0');
        d3.select(this.element.querySelector('.graph-tooltip'))
          .style('opacity', '0');

      })
      .on('mouseover', () => { // on cursor in show line, circles and text
        d3.select(this.element.querySelector('.cursor-vertical-line'))
          .style('opacity', '1');
        d3.selectAll('.cursor-per-line circle')
          .style('opacity', '1');
        d3.selectAll('.cursor-per-line text')
          .style('opacity', '1');
        d3.select(this.element.querySelector('.graph-tooltip'))
          .style('opacity', '1');
      });

    this.element.querySelector('.cursor-over-effects rect').addEventListener('mousemove', (ev) => {
      arrayFrom(this.element.querySelectorAll('.cursor-per-line')).forEach((line, i) => {
        d3.select(line).attr('transform', (d) => {
          const xDate = this.xScale.invert(ev.layerX - this.graphOptions.margins.left - 1);
          const bisect = d3.bisector(data => data.date).right;
          const valueIndex = bisect(d.values, xDate, 1);
          const value0 = d.values[valueIndex - 1];
          const value1 = d.values[valueIndex];
          const rangeValueOfFirst = this.xScale(value0.date);
          const rangeValueOfSecond = this.xScale(value1.date);
          const rangeValueOfCursorPos = ev.layerX - this.graphOptions.margins.left;
          // eslint-disable-next-line max-len
          const closestD = Math.abs(rangeValueOfCursorPos - rangeValueOfFirst) > Math.abs(rangeValueOfCursorPos - rangeValueOfSecond) ? value1 : value0;

          d3.select(this.element.querySelector('.cursor-vertical-line'))
            .attr('x1', this.xScale(closestD.date))
            .attr('x2', this.xScale(closestD.date));

          if (ev.layerX - this.graphOptions.margins.left >= (this.width - 220)) {
            d3.select(this.element.querySelector('.graph-tooltip'))
              .style('transform', `translate(${(this.xScale(closestD.date) - 220 - this.graphOptions.margins.left) + 10}px,${50}px)`);
          } else {
            d3.select(this.element.querySelector('.graph-tooltip'))
              .style('transform', `translate(${this.xScale(closestD.date) + 10}px, 50px)`);
          }

          const colorName = d.color.substring(1);
          d3.select(this.element.querySelector(`.span-set-${i}`)).html(`<span class='indicator-span-${colorName}'></span>${d.name}: <b>${closestD.value !== null ? closestD.value.toLocaleString(this.langCode) : '-'}</b>`);
          if (closestD.value === null) {
            d3.select(line).style('opacity', 0);
          } else {
            d3.select(line).style('opacity', 1);
          }

          return `translate(${this.xScale(closestD.date)}, ${this.yScale(closestD.value)})`;
        });
      });
    });
  }
}

export default GridDataGraph;
