import arrayFrom from 'array-from';
import Swiper from 'swiper/bundle';
import VideoTeaserLightbox from '../VideoTeaserLightbox';
import ModalLayer from '../ModalLayer';
import { wrapElement, addClass, generateSVGIcon } from '../../tools/helpers';

class ContentHubDefaultSlider {
  constructor(options = {}) {
    this.element = options.element;
    this.itemClass = options.itemClass || 'org--stage__media__item';
    this.items = arrayFrom(this.element.querySelectorAll(`.${this.itemClass}`));
    this.itemThumbsClass = 'org--gallery-thumbs__thumb-item';
    this.itemsThumbs = arrayFrom(this.element.querySelectorAll(`.${this.itemThumbsClass}`));
    this.wrapper = options.wrapper || null;
    this.thumbWrapper = this.element.querySelector('.org--gallery-thumbs__thumbs-wrapper');
    this.appendNavToElement = options.appendNavToElement || null;
    this.defaultContent = this.wrapper.cloneNode(true);
    this.showArrows = options.showArrows || false;
    this.showThumbs = options.showThumbs || false;
    this.showBullets = options.showBullets || false;
    this.smallArrowsNearBullets = options.smallArrowsNearBullets || false;
    this.showLightBox = options.showLightBox || false;
    this.imageClick = options.imageClick || false;
    this.trackTabbable = options.trackTabbable || false;
    this.options = options.options || {};
    this.autoplay = this.options.autoplay;
    this.callback = options.callback;
    this.initialSlide = options.initialSlide || null;
    this.itemsHaveLinks = false;
    this.galleryId = options.galleryId;
    this.slidesPerView = options.slidesPerView;
    this.slidesPerViewMobil = options.slidesPerViewMobil;
    this.slidesPerViewTablet = options.slidesPerViewTablet;
  }

  init() {
    this.prepareDom();
  }

  prepareDom() {
    if (this.wrapper !== null) {
      addClass(this.wrapper, 'swiper-container');
      this.wrapper.setAttribute('aria-live', 'polite');
    }

    // Check if there is at least two items for itemClass. -> if not leave the single Image Wrapper.
    if (this.wrapper.querySelectorAll(`.${this.itemClass}`).length > 1) {
      const slides = document.createElement('div');
      addClass(slides, 'swiper-wrapper');
      if (this.imageClick) {
        slides.setAttribute('tabindex', '0');
      }

      this.items.forEach((item) => {
        const slide = wrapElement(item, 'div', 'swiper-slide');
        slides.appendChild(slide);
        if (this.itemsHaveLinks === false && item.querySelector('a')) {
          this.itemsHaveLinks = true;
        }
      });

      this.wrapper.appendChild(slides);

      if (this.showArrows.toString() === 'true') {
        this.initArrows();
      }

      if (this.showBullets.toString() === 'true') {
        this.initBullets();
      }

      if (this.showLightBox.toString() === 'true') {
        this.initModal();
      }

      const readyInterval = window.setInterval(() => {
        const ready = this.element.querySelector('img').complete;

        if (ready) {
          window.clearInterval(readyInterval);

          this.initSlider(this.wrapper);

          this.fixDuplicateID(this.galleryId);
          this.addEventlisteners();
        }
      }, 20);
    }
  }

  prepareThumbs() {
    if (this.thumbWrapper !== null) {
      addClass(this.thumbWrapper, 'swiper-container');
      this.thumbWrapper.setAttribute('aria-live', 'polite');
    }

    // Check if there is at least two items for itemClass. -> if not leave the single Image Wrapper.
    if (this.thumbWrapper) {
      const slides = document.createElement('div');
      addClass(slides, 'swiper-wrapper');
      this.itemsThumbs.forEach((item) => {
        const slide = wrapElement(item, 'div', 'swiper-slide');
        addClass(slide, 'swiper-slide--thumb');
        slides.appendChild(slide);
      });
      this.thumbWrapper.appendChild(slides);

      this.arrowPrevThumb = document.createElement('div');
      addClass(this.arrowPrevThumb, 'swiper-button-prev');
      addClass(this.arrowNextThumb, 'swiper-button-prev--thumbs');
      this.arrowPrevThumb.setAttribute('aria-label', 'previous');
      this.arrowPrevThumb.setAttribute('value', 'prev');
      this.arrowPrevThumb.innerHTML = generateSVGIcon('icon-arrow-to-top');

      this.arrowNextThumb = document.createElement('div');
      addClass(this.arrowNextThumb, 'swiper-button-next');
      addClass(this.arrowNextThumb, 'swiper-button-next--thumbs');
      this.arrowNextThumb.setAttribute('aria-label', 'Next');
      this.arrowNextThumb.setAttribute('value', 'Next');
      this.arrowNextThumb.innerHTML = generateSVGIcon('icon-arrow-to-top');

      this.thumbWrapper.appendChild(this.arrowPrevThumb);
      this.thumbWrapper.appendChild(this.arrowNextThumb);

      const readyInterval = window.setInterval(() => {
        const ready = this.element.querySelector('img').complete;

        if (ready) {
          window.clearInterval(readyInterval);
        }

      }, 50);
    }
  }

  initArrows() {
    this.arrowPrev = document.createElement('div');
    addClass(this.arrowPrev, 'swiper-button-prev');
    this.arrowPrev.setAttribute('aria-label', 'previous');
    this.arrowPrev.setAttribute('value', 'prev');
    this.arrowPrev.innerHTML = this.smallArrowsNearBullets ? generateSVGIcon('icon-back') : generateSVGIcon('icon-arrow-to-top');

    this.arrowNext = document.createElement('div');
    addClass(this.arrowNext, 'swiper-button-next');
    this.arrowNext.setAttribute('aria-label', 'Next');
    this.arrowNext.setAttribute('value', 'Next');
    this.arrowNext.innerHTML = this.smallArrowsNearBullets ? generateSVGIcon('icon-forward') : generateSVGIcon('icon-arrow-to-top');

    if (!this.smallArrowsNearBullets) {
      if (this.appendNavToElement) {
        this.element.appendChild(this.arrowPrev);
        this.element.appendChild(this.arrowNext);
      } else {
        this.wrapper.appendChild(this.arrowPrev);
        this.wrapper.appendChild(this.arrowNext);
      }
    }
  }

  initBullets() {
    const pagination = document.createElement('div');
    addClass(pagination, 'slider__pagination');
    pagination.setAttribute('aria-controls', 'pagination');

    const bullets = document.createElement('div');
    addClass(bullets, 'slider__bullets');
    bullets.setAttribute('aria-controls', 'carousel');

    if (this.smallArrowsNearBullets) {
      this.pauseButton = document.createElement('button');
      addClass(this.pauseButton, 'slider__pause-button');
      addClass(this.pauseButton, 'slider__pause-button--stop');
      this.pauseButton.setAttribute('tabindex', '0');
      this.pauseButton.setAttribute('aria-controls', 'pause-button');
      this.pauseButton.innerHTML = generateSVGIcon('icon-stop-play');
      addClass(pagination, 'slider__pagination--with-arrows');
      pagination.appendChild(this.pauseButton);
      pagination.appendChild(this.arrowPrev);
      pagination.appendChild(bullets);
      pagination.appendChild(this.arrowNext);
    } else {
      pagination.appendChild(bullets);
    }

    if (this.appendNavToElement) {
      this.element.appendChild(pagination);
    } else {
      this.wrapper.appendChild(pagination);
    }
  }

  initPauseButton() {
    this.pauseButton.addEventListener('click', (event) => {
      event.preventDefault();
      if (this.pauseButton.classList.contains('slider__pause-button--stop')) {
        this.slider.autoplay.stop();
        this.pauseButton.classList.remove('slider__pause-button--stop');
        this.pauseButton.classList.add('slider__pause-button--start');
      } else if (this.pauseButton.classList.contains('slider__pause-button--start')) {
        this.slider.autoplay.start();
        this.pauseButton.classList.remove('slider__pause-button--start');
        this.pauseButton.classList.add('slider__pause-button--stop');
      }
    });
  }

  initSlider(wrapper) {
    if (this.showThumbs) {
      this.autoplay = false;
    }

    this.slider = new Swiper(wrapper, {
      spaceBetween: 10,
      centeredSlides: true,
      breakpoints: {
        320: {
          slidesPerView: this.slidesPerViewMobil,
        },
        768: {
          slidesPerView: this.slidesPerViewTablet,
        },
        992: {
          slidesPerView: this.slidesPerView,
        },
      },
      autoHeight: this.options.autoHeight,
      autoplay: this.autoplay,
      calculateHeight: true,
      loop: true,
      keyboard: {
        enabled: true,
        onlyInViewport: false,
      },
      loopAdditionalSlides: 0,
      preloadImages: true,
      updateOnImagesReady: true,
      updateOnWindowResize: true,
      initialSlide: this.initialSlide !== null ? this.initialSlide : null,
      a11y: {
        enabled: true,
      },
      pagination: {
        el: this.element.querySelector('.slider__bullets'),
        clickable: true,
      },
      navigation: {
        nextEl: this.element.querySelector('.swiper-button-next'),
        prevEl: this.element.querySelector('.swiper-button-prev'),
      },
      on: {
        init: () => {
          if (this.options.autoHeight.toString() === 'true' && this.showBullets === 'true') {
            if (!this.appendNavToElement && this.appendNavToElement === null) {
              this.updateBulletPosition();
            }
          }

          if (this.imageClick === 'true') {
            this.initImageClick();
          }

          if (this.smallArrowsNearBullets) {
            this.initPauseButton();
          }

          this.updateCaptchaHeight();
        },
      },
    });

    if (this.options.autoHeight.toString() === 'true' && this.showBullets === 'true') {
      this.slider.on('update', () => {
        if (!this.appendNavToElement && this.appendNavToElement === null) {
          this.updateBulletPosition();
        }
      });

      this.slider.on('slideChange', () => {
        if (!this.appendNavToElement && this.appendNavToElement === null) {
          this.updateBulletPosition();
        }
      });
    }

    window.addEventListener('resize', () => this.slider.update());

    window.addEventListener('resize', () => this.updateCaptchaHeight());

    if (this.itemsHaveLinks) {
      this.handleTabNavigation();
    }

    if (this.callback) {
      this.callback.call();
    }
  }

  updateBulletPosition() {
    window.setTimeout(() => {
      const figure = this.slider.slides[this.slider.activeIndex].querySelector('figure');
      const figcaption = this.slider.slides[this.slider.activeIndex].querySelector('figcaption');
      const PADDING_FOR_BUTTETS = 20;
      // eslint-disable-next-line max-len
      const imageHeight = figcaption ? (figure.getBoundingClientRect().height - figcaption.getBoundingClientRect().height) : figure.getBoundingClientRect().height;

      const offsetBullets = (Math.floor(imageHeight / 10) * 10) - PADDING_FOR_BUTTETS;
      const offsetArrows = (Math.floor(imageHeight / 10) * 10 / 2);

      this.setTabIndexPlayBtn(this.wrapper);

      window.setTimeout(() => {
        // this.element.querySelector('.slider__pagination').style.top = `calc(100% - ${offsetBullets}px)`;
        this.element.querySelector('.slider__pagination').style.top = `${offsetBullets}px`;
        this.element.querySelector('.swiper-button-prev').style.top = `${offsetArrows}px`;
        this.element.querySelector('.swiper-button-next').style.top = `${offsetArrows}px`;
      }, 100);
    }, 500);
  }

  initModal() {
    this.lightbox = new ModalLayer({
      element: this.element,
      content: document.querySelector(`[id="modal-layer-${this.galleryId}"]`),
    });

    this.lightbox.bindEvents();
    this.lightbox.replaceContent(this.defaultContent);

    this.lightboxSlider = new ContentHubDefaultSlider({
      element: document.querySelector(`[id="modal-layer-${this.galleryId}"]`),
      wrapper: document.querySelector(`[id="modal-layer-${this.galleryId}"] .modal-layer__content__holder > .org--carousel__wrapper`),
      spaceBetween: 10,
      centeredSlides: true,
      slidesPerView: 1,
      slidesPerViewMobil: 1,
      slidesPerViewTablet: 1,
      itemClass: this.itemClass,
      showArrows: true,
      showBullets: true,
      imageClick: false,
      keyboard: {
        enabled: true,
        onlyInViewport: true,
      },
      options: {
        autoHeight: true,
        autoplay: false,
        gap: 0,
        loop: true,
        loopAdditionalSlides: 0,
      },
    });

    this.lightboxSlider.prepareDom();
    window.setTimeout(() => {
      this.lightboxSlider.slider.on('slideChange', () => {
        this.slider.update();
        this.slider.slideToLoop(this.lightboxSlider.slider.realIndex);
      });

      // reset active video button in modal lightbox after slide is finished
      this.lightboxSlider.slider.on('slideChangeTransitionEnd', () => {
        this.setTabIndexPlayBtn(this.lightbox.content);
      });

      this.setTabIndexPlayBtn(this.lightbox.content);
    }, 1500);


    window.addEventListener('resize', () => {
      this.slider.update();
      this.lightboxSlider.slider.update();
    });
  }

  fixDuplicateID(galleryId) {
    if (!galleryId) {
      return;
    }

    const duplicateElement = document.querySelector(`#modal-layer-${galleryId} #gallery-${galleryId}`);
    duplicateElement.id = `module-gallery-${galleryId}`;
  }

  setTabIndexPlayBtn(wrapper) {
    arrayFrom(wrapper.querySelectorAll('.swiper-slide')).forEach((slide) => {
      const videoButton = slide.querySelector('.lightbox-trigger');
      if (videoButton) {
        if (slide.classList.contains('swiper-slide-active')) {
          videoButton.tabIndex = '0';
        } else {
          videoButton.tabIndex = '-1';
        }
      }
    });
  }

  addEventlisteners() {
    if (!this.galleryId) {
      return;
    }

    const moduleElements = document.querySelector(`[id="modal-layer-${this.galleryId}"]`).querySelectorAll('[data-module]');
    const duplicateSlidesElements = document.querySelectorAll(`#gallery-${this.galleryId} .swiper-slide.swiper-slide-duplicate [data-module]`);

    // initialize modules; see main/index.js
    arrayFrom(moduleElements).concat(arrayFrom(duplicateSlidesElements)).forEach((moduleElement) => {
      const moduleClassName = moduleElement.getAttribute('data-module');

      try {
        if (moduleClassName !== '') {
          const instance = new VideoTeaserLightbox({
            element: moduleElement,
          });
          instance.init();
        }
      } catch (er) {
        console.error(`error initializing module instance with class "${moduleClassName}"`);
        console.error(er);
      }
    });
  }

  initImageClick() {
    arrayFrom(this.wrapper.querySelectorAll('.swiper-slide')).forEach((slide) => {
      slide.addEventListener('click', (event) => {
        event.preventDefault();
        event.stopPropagation();
        this.setTabIndexPlayBtn(this.lightbox.content);
        if (slide.classList.contains('swiper-slide-active')) {
          this.lightboxSlider.slider.update();
          this.lightbox.showModal();
          this.lightboxSlider.slider.slideTo(parseInt(slide.dataset.swiperSlideIndex, 10) + 1);
        }
      });

      slide.addEventListener('keyup', (event) => {
        if (event.keyCode === 13) {
          event.preventDefault();
          event.stopPropagation();
          this.setTabIndexPlayBtn(this.lightbox.content);
          this.lightboxSlider.slider.update();
          this.lightbox.showModal();
          this.lightboxSlider.slider.slideTo(this.slider.activeIndex);
        }
      });
    });

    this.wrapper.querySelector('.swiper-wrapper').addEventListener('keyup', (event) => {
      if (event.keyCode === 13) {
        this.setTabIndexPlayBtn(this.lightbox.content);
        event.preventDefault();
        event.stopPropagation();
        this.lightboxSlider.slider.update();
        this.lightbox.showModal();
        this.lightboxSlider.slider.slideTo(this.slider.activeIndex);
      }
    });
  }

  /*
   sets the same size for all figure-captcha-elements to avoid context jumps under the slider
   */
  updateCaptchaHeight() {
    window.setTimeout(() => {
      this.mediaItems = arrayFrom(this.element.querySelectorAll('.org--carousel__media__item'));
      const captchas = arrayFrom(this.element.querySelectorAll('figcaption'));
      if (captchas.length > 0) {
        captchas.forEach((captchaElem) => {
          // eslint-disable-next-line no-param-reassign
          captchaElem.style.minHeight = 'auto';
        });
        const captchasHeight = captchas.map(item => item.getBoundingClientRect().height);
        const maxHeightOfElements = Math.max.apply(null, captchasHeight);
        this.mediaItems.forEach((item) => {
          const captcha = item.querySelector('figcaption');
          const CAPTCHA_PADDING = 10;
          if (captcha) {
            captcha.style.minHeight = `${maxHeightOfElements - CAPTCHA_PADDING}px`;
          } else {
            const figure = item.querySelector('figure');
            const emptyCaptcha = document.createElement('figcaption');
            figure.appendChild(emptyCaptcha);
            emptyCaptcha.style.minHeight = `${maxHeightOfElements - CAPTCHA_PADDING}px`;
          }
        });
        // fix height of active slider, height is defined by swiper-library and set before captcha-update
        // without this the hight of current slider is limited with the height that defined by swiper-library
        if (this.slider) {
          const activeHeight = this.slider.slides[this.slider.activeIndex].getBoundingClientRect().height;
          const swiperWrapper = this.slider.slides[this.slider.activeIndex].parentElement;
          const swiperWrapperHeight = swiperWrapper.style.height;
          // eslint-disable-next-line max-len
          const swiperWrapperHeightNumber = swiperWrapperHeight ? swiperWrapperHeight.slice(0, swiperWrapperHeight.length - 2) * 1 : 0;
          if (swiperWrapperHeightNumber < activeHeight) {
            swiperWrapper.style.height = `${activeHeight}px`;
          }
        }
      }
    }, 500);
  }

  // disable tab-navigation (tabindex = -1) for all sliders except first
  handleTabNavigation() {
    this.items.forEach((item, index) => {
      const links = item.querySelectorAll('a');
      if (links) {
        const tabindexValue = index === 0 ? 0 : -1;
        links.forEach(link => link.setAttribute('tabindex', tabindexValue));
      }
    });

    this.slider.on('slideChangeTransitionEnd', () => {
      this.updateAccessibilityForSlidersLinks();
    });
  }

  // change tabindex to "-1" for all inactive sliders links, and to "0" for active
  // change to '-1' for duplicate slides, to fix bug
  updateAccessibilityForSlidersLinks() {
    const slidesToUpdate = this.wrapper.querySelectorAll('.swiper-slide-duplicate, .swiper-slide-active, .swiper-slide-prev');
    slidesToUpdate.forEach((slide) => {
      const links = slide.querySelectorAll('a');
      if (links) {
        const tabindexValue = slide.classList.contains('swiper-slide-active') ? 0 : -1;
        links.forEach(link => link.setAttribute('tabindex', tabindexValue));
      }
    });
  }
}

export default ContentHubDefaultSlider;
