import { addClass, removeClass, hasClass } from '../../tools/helpers';

export const CLASSNAME_CONTENT = '.org--access-restriction__content-wrapper';
export const CLASSNAME_CONSENT = '.org--access-restriction__consent-wrapper';
export const CLASSNAME_BUTTON = '.org--access-restriction__button a';
export const CLASSNAME_CHECKBOX = '.org--access-restriction__checkbox';

class AccessRestriction {
  constructor(options = {}) {
    this.element = options.element;
    this.content = this.element.querySelector(CLASSNAME_CONTENT);
    this.consent = this.element.querySelector(CLASSNAME_CONSENT);
    this.button = this.element.querySelector(CLASSNAME_BUTTON);
    this.checkbox = this.element.querySelector(CLASSNAME_CHECKBOX);
  }

  init() {
    // timeout inserted so that the accordion can expand in the hidden part and take the right height
    window.setTimeout(() => {
      addClass(this.content, 'hidden');
    }, 10);

    this.button.addEventListener('click', (event) => {
      event.preventDefault();
      if (this.button.disabled === false) {
        if (hasClass(this.content, 'hidden')) {
          removeClass(this.content, 'hidden');
        }

        if (!hasClass(this.consent, 'hidden')) {
          addClass(this.consent, 'hidden');
        }
      }
    });

    this.checkbox.addEventListener('click', () => {
      if (this.checkbox.checked === true) {
        removeClass(this.button, 'background-color--neutral-grey-0');
        addClass(this.button, 'background-color--primary-fuchsia');
        this.button.disabled = false;
      }

      if (this.checkbox.checked === false) {
        removeClass(this.button, 'background-color--primary-fuchsia');
        addClass(this.button, 'background-color--neutral-grey-0');
        this.button.disabled = true;
      }
    });
  }

}

export default AccessRestriction;
