import Swiper from 'swiper/bundle';

class ContentHubBildErklaertextSlider {
  constructor(options = {}) {
    this.element = options.element;
    this.swiperContainer = this.element.querySelector('.swiper-container');
  }

  init() {
    this.expertSlider = new Swiper(this.swiperContainer, {
      slidesPerView: 1,
      spaceBetween: 16,
      pagination: {
        el: '.swiper-pagination',
        type: 'fraction',
        formatFractionCurrent(number) {
          return (`0${number}`).slice(-2);
        },
        formatFractionTotal(number) {
          return (`0${number}`).slice(-2);
        },
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      loop: true,
      direction: 'horizontal',

    });
  }

}

export default ContentHubBildErklaertextSlider;
