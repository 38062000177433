import { addClass, removeClass } from '../../tools/helpers';

class SliderCard {
  constructor(options = {}) {
    this.element = options.element;
    this.svg = this.element.querySelector('.slider-card__svg');
    this.text = this.element.querySelector('.slider-card__text');
    this.image = this.element.querySelector('.slider-card__img-wrapper img');
  }

  init() {
    this.initSliderCard();
  }

  initSliderCard() {

    this.element.addEventListener('focusin', (event) => {
      event.preventDefault();
      this.addClasses();
    });

    this.element.addEventListener('focusout', (event) => {
      event.preventDefault();
      this.removeClasses();
    });

    this.element.addEventListener('mouseover', (event) => {
      event.preventDefault();
      this.addClasses();
    });

    this.element.addEventListener('mouseout', (event) => {
      event.preventDefault();
      this.removeClasses();
    });
  }

  addClasses() {
    addClass(this.svg, 'active');
    addClass(this.text, 'active');
    addClass(this.image, 'active');
  }

  removeClasses() {
    removeClass(this.svg, 'active');
    removeClass(this.text, 'active');
    removeClass(this.image, 'active');
  }

}

export default SliderCard;
