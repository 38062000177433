import { addClass, removeClass, hexToRgb } from '../../tools/helpers';

const CLASSNAME_CONTENT = '.mol--partner-teaser-item__content';
const CLASSNAME_CONTENT_ACTIVE = '.mol--partner-teaser-item__content-detail';

class Systemvison2050PartnerTeaser {
  constructor(options = {}) {
    this.element = options.element;
    this.content = this.element.querySelector(CLASSNAME_CONTENT);
    this.contentActive = this.element.querySelector(CLASSNAME_CONTENT_ACTIVE);
    this.categoryColor = this.element.dataset.categoryColor || '#ffffff';
    this.categoryRGBAColor = hexToRgb(this.categoryColor, 0.3);
  }

  init() {
    this.contentActive.setAttribute('style', `background-color:${this.categoryRGBAColor};`);
    this.element.addEventListener('mouseover', (event) => {
      event.preventDefault();
      addClass(this.contentActive, 'active');
      addClass(this.content, 'inactive');
      removeClass(this.content, 'active');
    });

    this.element.addEventListener('mouseout', (event) => {
      event.preventDefault();
      removeClass(this.content, 'inactive');
      addClass(this.content, 'active');
      removeClass(this.contentActive, 'active');
    });
  }
}

export default Systemvison2050PartnerTeaser;
