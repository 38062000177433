import iFrameResize from 'iframe-resizer';

// this class has no function for the iframe newsletter
// it is only used to bring the iFrameResize Module in  the deployment process

class iFrameResizer {
  constructor(options = {}) {
    this.element = options.element;
  }

  init() {
    // eslint-disable-next-line
    const iframes = iFrameResize;
  }
}
export default iFrameResizer;
