class AlgoliaSearchBar {
  constructor(options = {}) {
    this.element = options.element;
    this.form = this.element.querySelector('form[name="search"]');
    this.queryInput = this.form.querySelector('input[name="korridor_b_de[query]"]');
    this.searchParams = new URLSearchParams(window.location.search);
    this.query = this.searchParams.get('korridor_b_de[query]') || null;
  }

  init() {
    if (this.query !== null) {
      this.queryInput.value = this.query;
    }
  }
}

export default AlgoliaSearchBar;
