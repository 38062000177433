import { addClass, findParent } from '../../tools/helpers';
import ConsentManager from '../ConsentManager';
import TemplateHandler from '../TemplateHandler';
import FeedTeaser from '../FeedTeaser';

class ConsentFeedTeaser {
  constructor(options = {}) {
    this.element = options.element;
    this.parent = findParent(this.element, 'mol--teaser-feed-item');
    this.consentNeeded = true;
    this.consentContent = TemplateHandler.getTemplate('consent-overlay-content', this.element);
  }

  init() {
    this.initConsentManager();
  }

  initConsentManager() {
    if (this.consentNeeded) {
      this.consentManager = new ConsentManager({
        element: this.element,
        consentTemplateId: this.element.querySelector('[data-template-name="consent-overlay-content"]').dataset.templateId,
        consentDataProcessor: this.element.querySelector('[data-template-name="consent-overlay-content"]').dataset.processor,
        callbackFail: () => this.insertConsent(),
        callbackSuccess: () => this.initContent(),
        callbackReinit: () => this.init(),
      });

      this.consentManager.init();
    }
  }

  insertConsent() {
    const content = document.createElement('div');
    addClass(content, 'consent__slot');
    content.insertAdjacentHTML('beforeend', this.consentContent);

    if (!this.parent.querySelector('.consent__wrapper')) {
      this.parent.appendChild(content);
      this.addLayerEvents();
    }
  }

  initContent() {
    addClass(this.parent.querySelector('.mol--teaser-feed-item__placeholder-image--desktop'), 'hidden');
    addClass(this.parent.querySelector('.mol--teaser-feed-item__placeholder-image--mobile'), 'hidden');

    if (this.parent.querySelector('.consent__slot')) {
      this.parent.removeChild(this.parent.querySelector('.consent__slot'));
    }

    // init FeedTeaser
    const instance = new FeedTeaser({
      element: this.parent,
    });
    instance.init();
  }

  addLayerEvents() {
    const buttonAccept = this.parent.querySelector('.give-consent');
    if (buttonAccept !== null) {
      buttonAccept.addEventListener('click', () => {
        this.consentManager.giveConsent();
      });
    }

    const buttonInfo = this.parent.querySelector('.more-info');
    if (buttonInfo !== null) {
      const templateId = this.element.querySelector('[data-template-name="consent-overlay-content"]').dataset.templateId;
      buttonInfo.addEventListener('click', () => {
        this.consentManager.toggleConsentWindow(templateId);
      });
    }
  }
}

export default ConsentFeedTeaser;
