import { hexToRgb } from '../../tools/helpers';

const CLASSNAME_CONTENT = '.mol--important-project-teaser-item__content';

class Systemvison2050ProjectTeaser {
  constructor(options = {}) {
    this.element = options.element;
    this.content = this.element.querySelector(CLASSNAME_CONTENT);
    this.categoryColor = this.element.dataset.categoryColor || '#ffffff';
    this.categoryRGBAColor = hexToRgb(this.categoryColor, 0.85);
  }

  init() {
    this.content.setAttribute('style', `background-color:${this.categoryRGBAColor};`);
  }
}

export default Systemvison2050ProjectTeaser;
