import arrayFrom from 'array-from';

import GridDataModel from './GridDataModel';
import { findParent, addClass } from '../../tools/helpers';

/**
 * This is the GridData Class. It uses a variation of the MVC-Principle and is therefore
 * (hopefully) very flexible and maintainable.
 */
class GridData {
  constructor(options = {}) {
    this.element = options.element;
    this.type = this.element.dataset.type;
    this.display = this.element.dataset.display;
    this.contentRow = findParent(this.element, 'org--content-row');
    this.index = arrayFrom(this.contentRow.classList).filter(value => value.includes('row-index'))[0].split('-')[2];
    this.viewOptions = {
      baseClass: arrayFrom(this.element.classList).filter(value => value.includes('org--'))[0],
      showTable: this.element.dataset.showTable.toString() || 'true',
      sortable: this.element.dataset.sortable.toString() || 'false',
      sortableColumn: this.element.dataset.sortableColumn || 0,
      reverseOrder: this.element.dataset.reverseOrder.toString(),
      showGraph: this.element.dataset.showGraph.toString() || 'true',
      showDatepicker: this.display === 'none' ? 'false' : this.element.dataset.showDatepicker.toString(),
      display: this.element.dataset.display.toString() || 'none',
      labels: this.element.dataset.labels || null,
      units: this.element.dataset.units || null,
      isYearPeak: this.element.dataset.isYearPeak.toString() || 'false',
      isHistory: this.element.dataset.isHistory.toString() || 'false',
    };
  }

  /**
   * Check if it is a History Indicator, and if not, fetch the Config.
   * If it is a History Indicator, set Config to Null.
   * Either way init the Model afterwards.
   */
  init() {
    if (this.viewOptions.isHistory !== 'true') {
      // fetch('https://internet-stage.ampintern.net/api/grid-data/config/EEG_RESTABWEICHUNG/quarterhour')
      fetch(`/api/grid-data/config/${this.type}/${this.display}`)
        .then(res => res.json())
        .then((result) => {
          this.config = result;
          this.initModel();
        })
        .catch((error) => {
          console.error('Config Fetch Failed: ', error);
          this.showErrorMessage();
          this.hideDatePicker();
        });
    } else {
      this.config = {
        latest: new Date(),
        first: new Date(),
      };
      this.initModel();
    }

    if (this.viewOptions.showDatepicker === 'false') {
      this.hideDatePicker();
    }
  }

  /**
   * Take the Constructor Options and Init the GridDataModel.
   */
  initModel() {
    const gridDataModel = new GridDataModel({
      element: this.element,
      type: this.type,
      display: this.display,
      viewOptions: this.viewOptions,
      config: this.config,
      index: this.index,
    });

    gridDataModel.init();
  }

  /**
   * Show the Error Message for missing Config.
   * This means taking the data-Attribute for the Error Message,
   * insert it into the Wrapper and make it visible.
   */
  showErrorMessage() {
    this.errorMessageConfig = this.element.dataset.errorMessageConfig || null;
    if (this.errorMessageConfig !== null) {
      const errorMessageConfigWrapper = this.element.querySelector(`.${this.viewOptions.baseClass}__error--config`);
      errorMessageConfigWrapper.innerHTML = this.errorMessageConfig;
      addClass(errorMessageConfigWrapper, 'shown');
    }
  }

  hideDatePicker() {
    addClass(this.element.querySelector(`.${this.viewOptions.baseClass}__filters`), 'hidden');
  }
}

export default GridData;
