import arrayFrom from 'array-from';
import { removeClass, addClass, hasClass } from '../../tools/helpers';

class ModalLayer {
  constructor(options = {}) {
    this.element = options.element;
    this.wrapper = options.content.querySelector('.modal-layer__wrapper');
    this.content = options.content.querySelector('.modal-layer__content__holder') || null;
    this.closeBtn = options.content.querySelector('.modal-layer__close') || null;
    this.callback = options.callback || null;
    this.modalLayerElement = options.content;
    this.insertedContentType = options.insertedContentType || null;
  }

  /**
   * init
   */
  init() {
    this.bindEvents();
  }

  /**
   * Bind Modal events
   */
  bindEvents() {
    if (!this.wrapper.hasAttribute('listenerOnClick')) {
      document.querySelector('.blur-overlay').addEventListener('click', (event) => {
        this.hideModal();
        event.stopPropagation();
      });

      this.wrapper.addEventListener('click', (event) => {
        this.hideModal();
        event.stopPropagation();
      });

      this.content.addEventListener('click', (ev) => {
        ev.stopPropagation();
      });

      this.closeBtn.addEventListener('click', (event) => {
        this.hideModal();
        event.stopPropagation();
      });

      this.closeBtn.addEventListener('keyup', (event) => {
        if (event.keyCode === 13) {
          event.preventDefault();
          this.hideModal();
          event.stopPropagation();
        }
      });

      document.addEventListener('keyup', (ev) => {
        if (ev.keyCode === 27 || ev.key === 'Escape') {
          if (hasClass(document.querySelector('body'), 'show-modal')) {
            ev.preventDefault();
            this.hideModal();
          }
        }
      });

      this.wrapper.setAttribute('listenerOnClick', 'true');
    }
  }

  /**
   * show the modalLayer
   */
  showModal() {
    const activeModals = document.querySelectorAll('.modal-layer.active');
    activeModals.forEach((activeModal) => {
      addClass(activeModal, 'modal-layer--hide-modal');
    });

    addClass(document.body, 'show-modal');
    addClass(this.modalLayerElement, 'active');
    removeClass(this.modalLayerElement, 'modal-layer--hide-modal');

    window.setTimeout(() => {
      this.closeBtn.focus();
    }, 100);
  }

  /**
   * replace the content in the modal
   * @param content
   */
  replaceContent(content) {
    this.content.innerHTML = '';
    this.content.appendChild(content);
    addClass(content, 'content-inserted');
    this.checkForIframes();
    if (this.callback !== null) {
      this.callback.call();
    }
  }

  /**
   * hide the modalLayer and focus the next possible element.
   */
  hideModal() {
    // there can be two modals beneath each other
    const numberOfModalsOpen = document.querySelectorAll('.modal-layer.active').length;
    if (numberOfModalsOpen < 2) {
      removeClass(document.body, 'show-modal');
    }

    removeClass(this.modalLayerElement, 'active');

    // this block triggers repainting, otherwise we will still see the close-button after the video disappears
    this.modalLayerElement.style.display = 'none';
    // eslint-disable-next-line no-unused-expressions
    this.modalLayerElement.offsetHeight;
    this.modalLayerElement.style.display = '';

    const hiddenModals = document.querySelectorAll('.modal-layer--hide-modal');
    hiddenModals.forEach((hiddenModal) => {
      removeClass(hiddenModal, 'modal-layer--hide-modal');
    });

    if (this.insertedContentType === 'video') {
      while (this.content.firstChild) {
        this.content.removeChild(this.content.firstChild);
      }
    }

    window.setTimeout(() => {
      arrayFrom(this.element.querySelectorAll('picture, button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'))[0].focus();
    }, 100);
  }

  /**
   * Check for IFrames and Resize them accordingly (hopefully).
   */
  checkForIframes() {
    const iframe = this.content.querySelector('iframe') || null;
    if (iframe !== null) {
      this.resizeIframe(iframe);
      window.addEventListener('resize', () => this.resizeIframe(iframe));
    }
  }

  resizeIframe(iframe) {
    const width = iframe.width || 640;
    const height = iframe.height || 480;

    const ratio = height / width;
    const newFrame = iframe;
    newFrame.width = this.content.getBoundingClientRect().width;
    newFrame.height = this.content.getBoundingClientRect().width * ratio;
  }
}

export default ModalLayer;
