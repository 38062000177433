import arrayFrom from 'array-from';
import twitter from 'twitter-text';

import DefaultSlider from '../DefaultSlider';
import { addClass, getWindowSize, removeClass } from '../../tools/helpers';
import { CONFIG } from '../config';
import getEventHandlerResize from '../ResizeHandler';

class FeedTeaser {
  constructor(options = {}) {
    this.element = options.element;
    this.container = this.element.querySelector('.mol--teaser-feed-item__content');

    this.props = {
      sources: {
        twitter:
          {
            itemsFolder: 'posts',
          },
      },
      lang: document.querySelector('html').lang,
      slider: true,
    };
    this.feedSourceDiv = document.documentElement.querySelector('.feeds-sources');
    this.readMore = 'Mehr erfahren';

    if (this.props.lang !== 'de') {
      this.readMore = 'Read More';
    }

    this.feeds = [];
    this.amountOfItems = 0;
    this.sourceNumber = 0;
    this.sliderContent = [];
    this.isUpdatingNow = false;
  }

  init() {
    this.readFeedsData();
    this.fetchData();
  }

  readFeedsData() {
    const feedSourceElements = arrayFrom(this.feedSourceDiv.querySelectorAll('.feeds-sources__elements'));
    feedSourceElements.forEach((element) => {
      const feed = {};
      feed.name = element.dataset.name;
      feed.index = element.dataset.feedPriority;
      feed.de = element.dataset.fetchUrlDe;
      feed.en = element.dataset.fetchUrlDe;
      feed.defaultImageUrl = element.dataset.feedFallbackImage.split(',');
      if (element.dataset.amountOfSlides) {
        feed.amountOfSlides = element.dataset.amountOfSlides;
      }

      this.feeds.push(feed);
    });
    this.defaultImageIndex = 0;
    this.parent = document.documentElement.querySelector('.org--teaser-box-group__wrapper');
    this.parent.removeChild(this.feedSourceDiv);
  }

  fetchData() {
    this.feeds.forEach((feed) => {
      fetch(feed[this.props.lang])
        .then(res => res.json())
        .then(result => this.renderItems(result, feed))
        .catch(error => this.handleError(error));
    });
  }

  handleError(error) {
    console.error(error);
    this.sourceNumber += 1;
    if (this.sourceNumber === this.feeds.length && this.props.slider && this.amountOfItems > 1) {
      this.renderSlider();
    }
  }

  renderItems(results, feed) {
    const itemsFolder = this.props.sources[feed.name].itemsFolder;
    const itemsSubFolder = this.props.sources[feed.name].itemsSubFolder || null;
    let items = itemsSubFolder ? results[itemsFolder][itemsSubFolder] : results[itemsFolder];
    if (feed.amountOfSlides) {
      items = items.filter(item => item.is_reply === 0);
      items.length = feed.amountOfSlides;
    }

    this.sourceNumber += 1;

    if (items.length > 0) {
      let content = '';
      items.forEach((item) => {
        content += this.applyLayout(item, feed);
      });
      // index is for the correct sequence of sliders if there are more than one source
      this.sliderContent.push({ index: feed.index, content });
      this.amountOfItems += items.length;
    }

    if (this.sourceNumber === this.feeds.length && this.props.slider && this.amountOfItems > 1) {
      this.renderSlider();
    }
  }

  renderSlider() {
    // correct sequence of sliders
    this.sliderContent.sort((a, b) => a.index - b.index);
    this.sliderContent.forEach(elem => this.container.insertAdjacentHTML('beforeend', elem.content));

    window.addEventListener('resize', () => this.updateElementsHeight());
    window.addEventListener('resize', () => this.updateTextLength(500));
    this.initSlider();
    this.updateTextLength();
    this.updateElementsHeight(1000);
  }

  applyLayout(item, feed) {
    const isRetweet = !!(item.originator_user_screenname && item.originator_user_screenname.length > 0);
    const link = item.url;
    const buttonText = this.readMore;
    // cut 2 first symbols "RT" from retweet text
    const text = isRetweet ? twitter.autoLink(item.text).slice(3) : twitter.autoLink(item.text);
    const img = item.image;
    const defaultImg = feed.defaultImageUrl[this.defaultImageIndex];

    if (!img) {
      // to use different default images
      // eslint-disable-next-line max-len
      this.defaultImageIndex = ((this.defaultImageIndex + 1) > (feed.defaultImageUrl.length - 1)) ? 0 : this.defaultImageIndex += 1;
    }

    const title = isRetweet ? `RETWEET AM ${this.dateConversion(item.source_created_at)}` : `TWEET AM ${this.dateConversion(item.source_created_at)}`;
    const slideColor = 'secondary-dark-green';
    const surtitle = '@Amprion auf Twitter';
    const elementClass = 'mol--teaser-feed-item__element--twitter';
    const secondImg = this.selectSecondImage(item);


    const template = `
      <div class="mol--teaser-feed-item__element ${elementClass} background-color--${slideColor}">
        <div class="mol--teaser-feed-item__element__media">
         ${secondImg || ''}   
      ${(!secondImg && img && img.length > 0) ?
    `<img src="${img}" class="mol--teaser-feed-item__element__img" />` : ''}
    ${(!secondImg && (!img || img.length === 0)) ? `<img src="${defaultImg}"/>` : ''}
          </div>
          <div class="mol--teaser-feed-item__element__description">
          <span class="mol--teaser-feed-item__element__description--surtitle">${surtitle}</span>  
          ${this.checkForContent(title, true) ? `<h3>
          ${this.checkForContent(item.timestamp, true) ?
    `<span class="mol--teaser-feed-item__element__description--date">${this.checkForContent(item.timestamp, true)}:&nbsp;</span>` : ''}
            ${this.checkForContent(title, true)}
          </h3>` : ''}
          ${text ? `<p>${text}${text.length === 200 ? '…' : ''}</p>` : ''}
          ${this.checkForContent(link, false) ? `<a href="${this.checkForContent(link, false)}" class="btn type-default background-color--primary-fuchsia" tabindex="-1" target="_blank">${buttonText}</a>` : ''}
        </div>
      </div>
    `;

    return template;
  }

  dateConversion(date) {
    return date.slice(0, 10).split('-').reverse().join('.');
  }

  /*
 Portrait images from Twitter are too small for feed module
  if image from twitter is Portrait find the second image
  if second image is Landscape - use the second one
  else puts 2 image nearby
   */
  selectSecondImage(item) {
    if (item.images && item.images.length > 1) {
      const firstImg = item.images[0];
      const isPortrait = firstImg.height * 1.1 > firstImg.width;
      if (isPortrait) {
        const secondImg = item.images[1];
        if (secondImg.height * 1.1 > secondImg.width) {
          return `<div class="mol--teaser-feed-item__element__double-images"><img src="${firstImg.url}" class="" /><img src="${secondImg.url}" class="" /></div>`;
        }

        return `<img src="${secondImg.url}" class="" />`;
      }
    }

    return null;
  }

  initSlider() {
    const slider = new DefaultSlider({
      element: this.element,
      itemClass: 'mol--teaser-feed-item__element',
      wrapper: this.element.querySelector('.mol--teaser-feed-item__content'),
      showBullets: true,
      showArrows: true,
      smallArrowsNearBullets: true,
      showLightBox: false,
      imageClick: false,
      options: {
        autoHeight: false,
        autoplay: {
          delay: 8000,
          disableOnInteraction: false,
        },
        gap: 0,
        loop: false,
      },
    });

    slider.init();
    if (this.element.classList.contains('hidden')) {
      removeClass(this.element, 'hidden');
    }
  }

  // this function fixes height of item description in mobile-view. this provides one color per slide
  // without this function, a part of the wrapper with a different color could be seen under the short descriptions
  updateElementsHeight() {
    const windowWidth = getWindowSize().width;
    // for mobile views only
    if (windowWidth <= CONFIG.viewports.find(config => config.name === 'SM').width) {
      window.setTimeout(() => {
        const itemDescriptions = this.element.querySelectorAll('.mol--teaser-feed-item__element__description');
        // clear height value
        arrayFrom(itemDescriptions).forEach((elem) => {
          // eslint-disable-next-line no-param-reassign
          elem.style.minHeight = 'auto';
        });
        const DESCRIPTION_PADDINGS = 40;

        const itemsHeights = arrayFrom(itemDescriptions).map(elem => elem.offsetHeight - DESCRIPTION_PADDINGS);
        const maxHeightOfElements = Math.max.apply(null, itemsHeights);
        arrayFrom(itemDescriptions).forEach((elem) => {
          // eslint-disable-next-line no-param-reassign
          elem.style.minHeight = `${maxHeightOfElements}px`;
        });
      }, 500);
    }
  }

  /*
  cut text and title length if they are too long
   */
  updateTextLength(delay) {
    // don't need for mobile views
    if (getEventHandlerResize().getCurrentViewport() === 'XS' || getEventHandlerResize().getCurrentViewport() === 'SM') {
      return;
    }

    if (this.isUpdatingNow) {
      return;
    }

    this.isUpdatingNow = true;
    window.setTimeout(() => {
      const itemDescriptions = this.element.querySelectorAll('.mol--teaser-feed-item__element__description');
      const MAX_TITLE_LENGTH = 35;

      arrayFrom(itemDescriptions).forEach((elem) => {
        const descriptionTitle = elem.querySelector('h3');
        if (descriptionTitle.innerText.length >= MAX_TITLE_LENGTH && !descriptionTitle.classList.contains('mol--teaser-feed-item__element__long-title')) {
          addClass(descriptionTitle, 'mol--teaser-feed-item__element__long-title');
        } else if (descriptionTitle.innerText.length < MAX_TITLE_LENGTH && descriptionTitle.classList.contains('mol--teaser-feed-item__element__long-title')) {
          removeClass(descriptionTitle, 'mol--teaser-feed-item__element__long-title');
        }

        const descriptionText = elem.querySelector('p');
        if (descriptionText.scrollHeight > descriptionText.clientHeight) {
          this.cropText(descriptionText, elem);
        }
      });
      this.isUpdatingNow = false;
    }, delay);
  }

  /*
   to crop text:
   - converts each word in text into span and puts them in the mark-up (only for node-elements it can be defined if they are visible or hidden)
   - extracts text of  all visible spans, ignores hidden (p in description has a 'max-height' attribute so a part of spans can be hidden with 'overflow: hidden')
   - adds ellipsis instead last word
   - converts spans-elements back into text and puts text in mark-up instead spans,
   */
  cropText(elem) {
    const text = elem.innerText;
    // eslint-disable-next-line no-param-reassign
    elem.innerText = '';
    const words = text.split(' ');
    const descriptionDiv = document.createElement('div');
    const descriptionSpansArr = [];
    words.forEach((word) => {
      const span = document.createElement('span');
      addClass(span, 'text-span');
      span.innerHTML = twitter.autoLink(word);
      descriptionDiv.appendChild(span);
      descriptionSpansArr.push(span);
    });
    addClass(descriptionDiv, 'text-words');
    elem.appendChild(descriptionDiv);

    const descriptionTextY = elem.getBoundingClientRect().top;
    window.setTimeout(() => {
      const textArray = [];
      const descriptionTextHeight = elem.getBoundingClientRect().height - 5;
      descriptionSpansArr.forEach((span) => {
        // if span visible
        if ((span.getBoundingClientRect().top - descriptionTextY) < descriptionTextHeight) {
          textArray.push(span.innerText);
        }
      });
      elem.removeChild(descriptionDiv);
      if (textArray.length > 10) {
        textArray.pop();
        textArray.push('...');
        // eslint-disable-next-line no-param-reassign
        elem.innerHTML = twitter.autoLink(textArray.join(' '));
      } else {
        // eslint-disable-next-line no-param-reassign
        elem.innerHTML = twitter.autoLink(text);
      }
    }, 500);
  }

  checkForContent(content, isEncoded = false) {
    let output = null;

    if (content && content.length > 0) {
      output = isEncoded ? decodeURIComponent(content) : content;
    }

    return output !== null ? output : false;
  }
}

export default FeedTeaser;
