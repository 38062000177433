import smoothscroll from 'smoothscroll-polyfill';

class ScrollHandler {
  constructor(options = {}) {
    this.element = options.element;
  }

  init() {
  }

  /**
   *
   * @param {*} container Represents the Container which is scrolled (needs overflow: scroll, i.e. Scrollbar) -- defaults to Window
   * @param {*} target The target to Scroll To. Usually the href on an anchor
   * @param {*} offset The offset to the target (i.e. Header height or something)
   * @param {*} smooth Enables native Smooth Scrolling, which is polyfilled.
   */
  /* eslint-disable */
  scrollToTopOfPage(container = window, smooth = true) {
    smoothscroll.polyfill();
    let scrollBehavior = null;
    if (smooth) {
      scrollBehavior = 'smooth';
    }

    container.scroll({ top: 0, left: 0, behavior: scrollBehavior });
  }


  /* eslint-disable */
  scrollContainerToTarget(container = window, target, offset, smooth = true) {
    smoothscroll.polyfill();
    const currentPosition = window.pageYOffset;
    const targetTop = target.getBoundingClientRect().top;
    const targetOffset = currentPosition + targetTop - offset;
    let scrollBehavior = null;

    if (smooth) {
      scrollBehavior = 'smooth';
    }

    container.scroll({ top: targetOffset, left: 0, behavior: scrollBehavior });
  }
  /* eslint-disable */
  scrollToTarget(container = window, target, offset, smooth = true) {
    smoothscroll.polyfill();
    const currentPosition = target.scrollTop;
    const targetTop = target.offsetTop;
    const targetOffset = currentPosition + targetTop - offset;

    container.scroll({ top: targetOffset, left: 0 });
  }
}

export default ScrollHandler;
