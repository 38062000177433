/**
 * e-mail Linktemplate in FirstSpirit writes the e-mail address and the link text reversed into HTML.
 * the e-mail address is reverted again in the Browser and then displayed correctly.
 * implemented to protect the e-mail address from Bots.
 */

class BackButtonHandler {
  constructor(options = {}) {
    this.element = options.element;
  }

  init() {
    this.element.addEventListener('click', (event) => {
      event.preventDefault();
      // eslint-disable-next-line no-restricted-globals
      history.back();
    });
  }

}

export default BackButtonHandler;
