class SearchBar {
  constructor(options = {}) {
    this.element = options.element;
    this.form = this.element.querySelector('form');
    this.queryInput = this.form.querySelector('input[name="query"]');
    this.button = this.element.querySelector('button[type="submit"]');
    this.searchParams = new URLSearchParams(window.location.search);
    this.query = this.searchParams.get('query') || null;
  }

  /**
   *
   */
  init() {
    if (this.query !== null) {
      this.queryInput.value = this.query;
    }

    // Reserve Code for Event-Based Form Application.

    // this.button.addEventListener('click', (ev) => {
    //   const form = findParent(ev.target, 'form');

    //   if (form.reportValidity()) {
    //     const formData = new FormData(form);
    //     const data = arrayFrom(formData.entries()).reduce((memo, pair) => ({
    //       ...memo,
    //       [pair[0]]: pair[1],
    //     }), {});

    //     dispatchCustomEvent('search-query-changed', {
    //       params: {
    //         lang: data.lang,
    //         query: data.query,
    //       },
    //     });
    //   }
    // });
  }
}

export default SearchBar;
