const breakpointKeys = {
  xs: 'XS',
  ms: 'MS',
  sm: 'SM',
  md: 'MD',
  lg: 'LG',
  xl: 'XL',
  xxl: 'XXL',
};

const config = {
  events: {
    resize: 'amp-event-resize',
    viewportChange: 'amp-event-viewport-changed',
  },
  viewports: [
    {
      name: breakpointKeys.xs,
      width: 320,
    },
    {
      name: breakpointKeys.ms,
      width: 480,
    },
    {
      name: breakpointKeys.sm,
      width: 768,
    },
    {
      name: breakpointKeys.md,
      width: 992,
    },
    {
      name: breakpointKeys.lg,
      width: 1200,
    },
    {
      name: breakpointKeys.xl,
      width: 1500,
    },
    {
      name: breakpointKeys.xxl,
      width: 1800,
    },
  ],
};

export {
  config as CONFIG,
  breakpointKeys as BREAKPOINTS,
};
