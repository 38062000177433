/* eslint-disable */

class SankeyChart {
  constructor(options = {}) {
    this.element = options.element;
    this.dataChart = dataChart;
  }

  init() {
    let plotly = Plotly.newPlot(this.element, this.dataChart);
  }
}

export default SankeyChart;
