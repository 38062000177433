import ConsentManager from '../ConsentManager';
import ModalLayer from '../ModalLayer';
import TemplateHandler from '../TemplateHandler';

class VideoTeaserLightbox {
  constructor(options = {}) {
    this.element = options.element;
    this.lightboxButton = this.element.querySelector('.lightbox-trigger');
    this.consentNeeded = true;
    this.videoId = this.element.dataset.videoId;
    this.consentContent = TemplateHandler.getTemplate('consent-overlay-content', this.element);
    this.lightboxContent = TemplateHandler.getTemplate('consent-given-content', this.element);
  }

  init() {
    this.initLightbox();
  }

  initLightbox() {
    this.lightboxButton.addEventListener('click', (event) => {
      this.openModal();
      event.stopPropagation();
      event.preventDefault();
    });
    this.lightboxButton.addEventListener('keyup', (event) => {
      if (event.keyCode === 13) {
        this.openModal();
        event.stopPropagation();
        event.preventDefault();
      }
    });
  }

  openModal() {
    this.lightbox = new ModalLayer({
      element: this.element,
      content: document.querySelector(`[id="modal-layer-${this.videoId}"]`),
      insertedContentType: 'video',
    });
    this.initConsentManager();
    this.lightbox.bindEvents();
  }

  initConsentManager() {
    // console.log('Init COnsent Manaager in FUnction', this.consentNeeded);
    if (this.consentNeeded) {
      this.consentManager = new ConsentManager({
        element: this.element,
        consentTemplateId: this.element.querySelector('[data-template-name="consent-overlay-content"]').dataset.templateId,
        consentDataProcessor: this.element.querySelector('[data-template-name="consent-overlay-content"]').dataset.processor,
        callbackFail: () => this.insertConsent(),
        callbackSuccess: () => this.insertContent(),
        callbackReinit: () => this.init(),
      });

      this.consentManager.init();
      // this.checkForConsent();
    }
  }

  insertConsent() {
    const content = document.createElement('section');
    content.insertAdjacentHTML('beforeend', this.consentContent);
    this.lightbox.replaceContent(content);
    this.addLayerEvents();
    this.lightbox.showModal();
  }

  insertContent() {
    const content = document.createElement('section');
    content.insertAdjacentHTML('beforeend', this.lightboxContent);
    this.lightbox.replaceContent(content);
    this.lightbox.showModal();
  }

  addLayerEvents() {
    const buttonAccept = document.querySelector('body').querySelector(`[id="modal-layer-${this.videoId}"]`).querySelector('.give-consent');
    if (buttonAccept !== null) {
      buttonAccept.addEventListener('click', () => {
        this.consentManager.giveConsent();
      });
    }

    const buttonInfo = document.querySelector('body').querySelector(`[id="modal-layer-${this.videoId}"]`).querySelector('.more-info');
    if (buttonInfo !== null) {
      const templateId = this.element.querySelector('[data-template-name="consent-overlay-content"]').dataset.templateId;
      buttonInfo.addEventListener('click', () => {
        this.consentManager.toggleConsentWindow(templateId);
      });
    }
  }
}

export default VideoTeaserLightbox;
