import arrayFrom from 'array-from';

import { addClass, hasClass, removeClass } from '../../tools/helpers';

export const CLASSNAME_LEVEL_1_ITEMS = 'button.navbar__nav-item__first-level-link';
export const CLASSNAME_ACTIVE = 'is-active';
export const CLASSNAME_OPENED = 'opened';
export const CLASSNAME_AFFIX = 'affix';
export const CLASSNAME_AFFIX_TOP = 'affix-top';
export const CLASSNAME_NO_AFFIX = 'affix-no';

class DefaultNavigation {
  constructor(options = {}) {
    this.element = options.element;
    this.offset = this.element.dataset.offsetTop || null;
    this.level1Items = this.element.querySelectorAll(`${CLASSNAME_LEVEL_1_ITEMS}`);
    this.blurOverlay = document.documentElement.querySelector('.nav-blur-overlay');
  }

  init() {
    this.bindEvents();
    this.addScrollListener();
  }

  bindEvents() {
    document.querySelector('body').addEventListener('click', () => {
      if (arrayFrom(this.level1Items).some(item => hasClass(item, `${CLASSNAME_ACTIVE}`))) {
        this.closeAllSubNavigations();
      }
    });

    arrayFrom(this.level1Items).forEach((item) => {
      item.addEventListener('click', (event) => {
        event.stopPropagation();
        this.closeAllSubNavigations(item);

        if (!hasClass(item, `${CLASSNAME_ACTIVE}`)) {
          this.openSubNavigation(item);
        } else if (hasClass(item, `${CLASSNAME_ACTIVE}`)) {
          this.closeSubNavigation(item);
        }
      });
    });
  }

  closeAllSubNavigations(excludedItem) {
    arrayFrom(this.level1Items).filter(item => item !== excludedItem).forEach((item) => {
      this.closeSubNavigation(item);
    });
  }

  openSubNavigation(item) {
    addClass(item, `${CLASSNAME_ACTIVE}`);
    const subNavigation = item.parentNode.querySelector('.navbar__nav-item__sub-navigation');

    if (subNavigation !== null) {
      addClass(subNavigation, `${CLASSNAME_OPENED}`);
      this.repositionIfPartiallyVisibleAtTheRightFrame(subNavigation);
      subNavigation.setAttribute('aria-expanded', true);
      addClass(this.blurOverlay, `${CLASSNAME_OPENED}`);
    }
  }

  repositionIfPartiallyVisibleAtTheRightFrame(el) {
    const rect = el.getBoundingClientRect();
    const outsideInPixel = rect.right -
      document.documentElement.clientWidth +
      (window.innerWidth - document.documentElement.clientWidth);

    // check if the element is more than 20 pixel outside the view.
    // These 20 pixels represent a padding of the <li> child elements.
    if (outsideInPixel >= 20) {

      // this checks several cases of the element being partially visible
      if (!el.classList.contains('moved-50') &&
        !el.classList.contains('moved-100') &&
        !el.classList.contains('moved-150')) {

        // check if outsidePixel is larger than the padding of 20 px and if yes set the appropriate class
        if (outsideInPixel <= 50) {
          addClass(el, 'moved-50');
        } else if (outsideInPixel <= 100) {
          addClass(el, 'moved-100');
        } else if (outsideInPixel > 100) {
          addClass(el, 'moved-150');
        }
      } else {
        // check if a class is already set but there the element is not fully visible anymore (resizing)
        if (el.classList.contains('moved-50')) {
          if (outsideInPixel <= 100) {
            removeClass(el, 'moved-50');
            addClass(el, 'moved-100');
          } else {
            // outsideInPixel > 100
            removeClass(el, 'moved-50');
            addClass(el, 'moved-150');
          }
        }

        if (el.classList.contains('moved-100')) {
          removeClass(el, 'moved-100');
          addClass(el, 'moved-150');
        }
      }
    }
  }

  closeSubNavigation(item) {
    removeClass(item, `${CLASSNAME_ACTIVE}`);

    const subNavigation = item.parentNode.querySelector('.navbar__nav-item__sub-navigation');

    if (subNavigation !== null) {
      removeClass(subNavigation, `${CLASSNAME_OPENED}`);
      subNavigation.setAttribute('aria-expanded', false);
    }

    removeClass(this.blurOverlay, `${CLASSNAME_OPENED}`);
  }

  addScrollListener() {
    if (hasClass(this.element, CLASSNAME_NO_AFFIX)) {
      return;
    }

    let scrollPosition = window.pageYOffset;

    if (scrollPosition !== null && scrollPosition >= this.offset) {
      addClass(this.element, `${CLASSNAME_AFFIX}`);
      removeClass(this.element, `${CLASSNAME_AFFIX_TOP}`);
    } else if (scrollPosition !== null && scrollPosition <= this.offset) {
      addClass(this.element, `${CLASSNAME_AFFIX_TOP}`);
      removeClass(this.element, `${CLASSNAME_AFFIX}`);
    }

    window.addEventListener('scroll', () => {
      scrollPosition = window.pageYOffset;

      if (scrollPosition !== null && scrollPosition >= this.offset) {
        addClass(this.element, `${CLASSNAME_AFFIX}`);
        removeClass(this.element, `${CLASSNAME_AFFIX_TOP}`);
      } else if (scrollPosition !== null && scrollPosition <= this.offset) {
        addClass(this.element, `${CLASSNAME_AFFIX_TOP}`);
        removeClass(this.element, `${CLASSNAME_AFFIX}`);
      }
    });
  }
}

export default DefaultNavigation;
