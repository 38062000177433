import 'url-search-params-polyfill';
import algoliasearch from 'algoliasearch/lite';
import instantsearch from 'instantsearch.js';

import { configure, hits, pagination, searchBox, stats } from 'instantsearch.js/es/widgets';
import { addClass, removeClass } from '../../tools/helpers';

const RESULTS_CONTAINER_ID = 'hits';
const PAGINATION_CONTAINER = 'pagination';

class AlgoliaSearch {
  constructor(options = {}) {
    this.element = options.element;
    this.searchIndexName = this.element.dataset.searchIndexName || '';
    this.apiId = this.element.dataset.searchApiId || '';
    this.apiKey = this.element.dataset.searchApiKey || '';
    this.searchIndexName = this.element.dataset.searchIndexName || '';
    this.searchInputPlaceholder = this.element.dataset.searchInputPlaceholder || '';
    this.searchSubmitButtonText = this.element.dataset.searchSubmitButtonText || '';
    this.searchResultMsg = this.element.dataset.searchResultMsg || '';
    this.searchResultMsgOneResult = this.element.dataset.searchResultMsgOneResult || '';
    this.searchResultMsgEmpty = this.element.dataset.searchResultMsgEmpty || '';
    this.searchBarHeader = document.querySelectorAll(`input[name="${this.searchIndexName}[query]"]`);
    this.searchResultsContainer = document.getElementById(RESULTS_CONTAINER_ID);
    this.paginationContainer = document.getElementById(PAGINATION_CONTAINER);
  }


  init() {
    const algoliaClient = algoliasearch(
      this.apiId,
      this.apiKey,
    );

    const searchClient = {
      algoliaClient,
      search(requests) {
        const errorInfo = document.getElementById('error');
        addClass(errorInfo, 'hidden');

        if (requests.every(({ params }) => !params.query)) {
          return Promise.resolve({
            results: requests.map(() => ({
              hits: [],
              nbHits: 0,
              nbPages: 0,
              page: 0,
              processingTimeMS: 0,
              hitsPerPage: 0,
              exhaustiveNbHits: false,
              query: '',
              params: '',
            })),
          });
        }

        return algoliaClient.search(requests).catch((error) => {
          // rate Limit reached - algolia answers with '429 Too Many Requests'
          if (error.status === 429) {
            removeClass(errorInfo, 'hidden');
          }

          return Promise.reject(error);
        });
      },
    };

    const search = instantsearch({
      searchClient,
      indexName: this.searchIndexName,
      // cleanUrlOnDispose: true,
      routing: true,
      onStateChange: ({ uiState, setUiState }) => {
        const query = uiState[this.searchIndexName].query;
        this.customFilters(query);
        setUiState(uiState);
      },
      future: {
        preserveSharedStateOnUnmount: false,
      },
    });

    search.addWidgets(this.getSearchWidgets());
    search.on('render', this.handleRender.bind(this));
    search.start();
  }

  getSearchWidgets() {
    const searchResultMsgOneResult = this.searchResultMsgOneResult;
    const searchResultMsg = this.searchResultMsg;
    return [
      configure({
        attributesToSnippet: [
          'title',
          'content:30', // 30 words
        ],
        hitsPerPage: 10,
      }),
      searchBox({
        container: '#searchbox',
        searchAsYouType: false,
        showReset: false,
        placeholder: this.searchInputPlaceholder,
        templates: {
          // eslint-disable-next-line no-empty-pattern
          submit: ({}, { html }) => html`
            <svg class="ais-SearchBox-submitIcon" xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 40 40">
              <path d="M26.804 29.01c-2.832 2.34-6.465 3.746-10.426 3.746C7.333 32.756 0 25.424 0 16.378 0 7.333 7.333 0 16.378 0c9.046 0 16.378 7.333 16.378 16.378 0 3.96-1.406 7.594-3.746 10.426l10.534 10.534c.607.607.61 1.59-.004 2.202-.61.61-1.597.61-2.202.004L26.804 29.01zm-10.426.627c7.323 0 13.26-5.936 13.26-13.26 0-7.32-5.937-13.257-13.26-13.257C9.056 3.12 3.12 9.056 3.12 16.378c0 7.323 5.936 13.26 13.258 13.26z"></path>
            </svg><span class="submit-button-text">${this.searchSubmitButtonText}</span>
          `,
        },
      }),
      stats({
        container: '#stats-hits',
        templates: {
          text(data, { html }) {
            let resultText = '';

            if (data.hasOneResult) {
              resultText += `1 ${searchResultMsgOneResult}`;
            } else {
              resultText += `${data.nbHits} ${searchResultMsg}`;
            }

            return html`${resultText}`;
          },
        },
      }),
      hits({
        container: '#hits',
        empty: this.searchResultMsgEmpty,
        templates: {
          // eslint-disable-next-line no-empty-pattern
          empty: ({}, { html }) => html`
            ${this.searchResultMsgEmpty}
          `,
          item(hit, { html, components }) {
            return html`
              <a href="${hit.url}">
                <div class="ais-Hits-item__headline">
                  <h3>${components.Snippet({ hit, attribute: 'title' })}</h3>
                  ${hit.isNetzjournal ? html`<div class="ais-Hits-item__headline__tag">Netzjournal</div>` : ''}
                </div>
                <div class="ais-Hits-item__wrapper">
                   ${hit.fileSize ? html`
                  <div class="ais-Hits-item__wrapper__download">
                    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M3 14C3.55228 14 4 14.4477 4 15V19C4 19.2652 4.10536 19.5196 4.29289 19.7071C4.48043 19.8946 4.73478 20 5 20H19C19.2652 20 19.5196 19.8946 19.7071 19.7071C19.8946 19.5196 20 19.2652 20 19V15C20 14.4477 20.4477 14 21 14C21.5523 14 22 14.4477 22 15V19C22 19.7957 21.6839 20.5587 21.1213 21.1213C20.5587 21.6839 19.7957 22 19 22H5C4.20435 22 3.44129 21.6839 2.87868 21.1213C2.31607 20.5587 2 19.7957 2 19V15C2 14.4477 2.44772 14 3 14Z" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.29289 9.29289C6.68342 8.90237 7.31658 8.90237 7.70711 9.29289L12 13.5858L16.2929 9.29289C16.6834 8.90237 17.3166 8.90237 17.7071 9.29289C18.0976 9.68342 18.0976 10.3166 17.7071 10.7071L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L6.29289 10.7071C5.90237 10.3166 5.90237 9.68342 6.29289 9.29289Z" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M12 2C12.5523 2 13 2.44772 13 3V15C13 15.5523 12.5523 16 12 16C11.4477 16 11 15.5523 11 15V3C11 2.44772 11.4477 2 12 2Z" />
                    </svg>
                    <div class="filesize">${hit.fileSize}</div>
                    <div class="download-url">${hit.url}</div>
                  </div>
                  ` : ''}
                </div>
                <div class="page-content">${components.Snippet({ hit, attribute: 'content' })}</div>
              </a>
            `;
          },
        },

      }),
      pagination({
        container: '#pagination',
      }),
    ];
  }

  handleRender() {
    const numberOfHits = parseInt(this.searchResultsContainer.getElementsByClassName('ais-Hits-item').length, 10);
    this.togglePagination(numberOfHits);
    this.updateSearchBarInHeader();
  }

  togglePagination(numberOfHits) {
    if (numberOfHits > 0) {
      removeClass(this.paginationContainer, 'hidden');
    } else {
      addClass(this.paginationContainer, 'hidden');
    }
  }

  updateSearchBarInHeader() {
    // set the search term as value in the searchbar in the header (we have 2 searchbars: desktop and mobile)
    this.searchBarHeader.forEach((input) => {
      // eslint-disable-next-line no-param-reassign
      input.value = document.querySelector('.ais-SearchBox-input').value || '';
    });
  }

  customFilters(query) {
    if (query) {
      if (query.length < 3) {
        removeClass(document.getElementById('info'), 'hidden');
        addClass(document.getElementById('hits'), 'hidden');
        addClass(document.getElementById('stats-hits'), 'hidden');
        addClass(document.getElementById('pagination'), 'hidden');
        return false;
      }
    }

    addClass(document.getElementById('info'), 'hidden');
    removeClass(document.getElementById('hits'), 'hidden');
    removeClass(document.getElementById('stats-hits'), 'hidden');
    removeClass(document.getElementById('pagination'), 'hidden');
    return true;
  }
}

export default AlgoliaSearch;
