import arrayFrom from 'array-from';

import { addClass, removeClass } from '../../tools/helpers';

class Pagination {
  constructor(options = {}) {
    this.element = options.element;
    this.prevButton = this.element.querySelector('.prev');
    this.nextButton = this.element.querySelector('.next');
    this.pagesWrapper = this.element.querySelector('.pages__wrapper');
    this.label = this.pagesWrapper.dataset.label;
    this.dispatchEvent = true;
    this.searchParams = true;

    this.hasBeenThereBefore = false;

    this.params = options.params;
    this.resultsPerPage = options.resultsPerPage || 5;
    this.callback = options.callback || null;

    this.buttonRange = 4;
  }

  init() {
  }

  addPagination() {
    // this.clearPagination();
    if (this.params.totalHits >= this.resultsPerPage) {
      for (let i = 2; i < this.numPages(); i += 1) {
        const pageButton = document.createElement('button');
        addClass(pageButton, 'pagination-page');
        pageButton.innerHTML = i;
        pageButton.setAttribute('data-index', i);
        this.pagesWrapper.querySelector('.pages__content').appendChild(pageButton);
      }

      arrayFrom(this.element.querySelectorAll('button.pagination-page')).forEach((pageButton, index) => {
        pageButton.setAttribute('aria-label', `${this.label} ${index + 1}`);

        pageButton.addEventListener('click', () => {
          if (Number(pageButton.dataset.index) !== this.params.currentPage) {
            this.params.currentPage = Number(pageButton.dataset.index);
            this.callback(this.params);
          }
        });
      });

      const lastButton = this.pagesWrapper.querySelector('.pagination-page.last');
      lastButton.innerHTML = this.numPages();
      lastButton.dataset.index = this.numPages();

      this.selectedPage();
      this.showPagination();
    }
  }

  addEventListeners() {
    if (!this.hasBeenThereBefore) {
      this.prevButton.addEventListener('click', () => this.prevPage());
      this.nextButton.addEventListener('click', () => this.nextPage());
    }
  }

  selectedPage() {
    const buttons = this.pagesWrapper.querySelectorAll('button.pagination-page');
    arrayFrom(buttons).forEach((button) => {
      if (Number(button.dataset.index) === this.params.currentPage) {
        addClass(button, 'active');
      } else {
        removeClass(button, 'active');
      }
    });

    if (this.params.currentPage === this.numPages() && !this.nextButton.classList.contains('button-hidden')) {
      this.nextButton.classList.add('button-hidden');
    } else if (this.params.currentPage !== this.numPages() && this.nextButton.classList.contains('button-hidden')) {
      this.nextButton.classList.remove('button-hidden');
    }

    if (this.params.currentPage === 1 && !this.prevButton.classList.contains('button-hidden')) {
      this.prevButton.classList.add('button-hidden');
    } else if (this.params.currentPage !== 1 && this.prevButton.classList.contains('button-hidden')) {
      this.prevButton.classList.remove('button-hidden');
    }

    this.shortenPages();

  }

  prevPage() {
    if (this.params.currentPage >= 1) {
      this.params.currentPage = this.params.currentPage - 1;
      this.callback.call(this.params);
    }
  }

  nextPage() {

    if (this.params.currentPage < this.numPages()) {
      this.params.currentPage += 1;
      this.callback.call(this.params);
    }
  }

  numPages() {
    return Math.ceil(this.params.totalHits / this.resultsPerPage);
  }

  clearPagination() {
    this.pagesWrapper.innerHTML = '';
  }

  shortenPages() {
    const buttons = this.pagesWrapper.querySelectorAll('button.pagination-page');

    arrayFrom(buttons).forEach((button) => {
      if (this.isBetween(Number(button.dataset.index))) {
        addClass(button, 'shown');
      } else {
        removeClass(button, 'shown');
      }
    });

    const activeButtons = this.pagesWrapper.querySelectorAll('button.shown');
    const firstDots = this.pagesWrapper.querySelector('.dots-before');

    if (arrayFrom(activeButtons).some(button => Number(button.dataset.index) === 2)) {
      addClass(firstDots, 'hidden');
    } else {
      removeClass(firstDots, 'hidden');
    }

    const lastDots = this.pagesWrapper.querySelector('.dots-after');
    if (arrayFrom(activeButtons).some(button => Number(button.dataset.index) === this.numPages() - 1)) {
      addClass(lastDots, 'hidden');
    } else {
      removeClass(lastDots, 'hidden');
    }
  }

  isBetween(x) {
    const half = Math.ceil((this.buttonRange / 2) - 1);
    return x >= this.params.currentPage - half - 1 && x <= (this.params.currentPage + half + 3);
  }

  showPagination() {
    removeClass(this.element, 'hidden');
  }
}

export default Pagination;
