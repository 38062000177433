import { dispatchCustomEvent } from '../../tools/helpers';

class ConsentManager {
  constructor(options = {}) {
    this.element = options.element;
    this.consentTemplateId = options.consentTemplateId;
    this.consentDataProcessor = options.consentDataProcessor;

    // this.consentOverlayContent = options.consentOverlayContent;
    // this.consentGivenContent = options.consentGivenContent;
    this.callbackFail = options.callbackFail;
    this.callbackSuccess = options.callbackSuccess;
    this.callbackReinit = options.callbackReinit || null;
  }

  init() {
    // console.log('Init ConsentManager', this.consentTemplateId, this.consentDataProcessor);
    // this.consentGiven = this.currentConsentState(this.consentTemplateId);
    this.addEvents();
    this.checkForConsent();
  }

  addEvents() {
    window.addEventListener('consent-changed', () => {
      this.checkForConsent();
    });
    if (this.callbackReinit) {
      window.addEventListener('general-consent-changed', () => {
        window.setTimeout(() => {
          this.callbackReinit();
        }, 500);
      });
    }

    window.setTimeout(() => {
      this.addUCBannerClickEvent();
    }, 1000);
  }

  /*
  this event listener helps to restart the modul, if us-consent modal & consent slot are of the same page,
  then after clicking the "accept" button in gemeral modal, the consent-slot will be removed from the modul
   */
  addUCBannerClickEvent() {
    if (!this.currentConsentState(this.consentTemplateId) && this.callbackReinit) {
      const ucBanner = document.querySelector('#usercentrics-root').shadowRoot.querySelector('#uc-center-container');
      if (ucBanner) {
        const acceptButton = ucBanner.querySelector('[data-testid=\'uc-accept-all-button\']');
        acceptButton.addEventListener('click', () => {
          dispatchCustomEvent('general-consent-changed', {});
        });
      }
    }
  }

  checkForConsent() {
    if (this.consentTemplateId !== null) {
      // console.log('CURRENT STATE', this.currentConsentState(this.consentTemplateId));
      // console.log('iswhitelisted:',window.uc.whitelisted.includes(this.consentTemplateId));
      const isWhitelisted = window.uc.whitelisted.has(this.consentTemplateId) || false;
      if (!this.currentConsentState(this.consentTemplateId) && !isWhitelisted) {
        this.callbackFail.call();
      } else if (this.currentConsentState(this.consentTemplateId) || isWhitelisted) {
        this.callbackSuccess.call();
      }
    }
  }

  giveConsent() {
    // console.log('Giving my Consent');
    const UC_UI = window.UC_UI || null;

    if (UC_UI !== null) {
      UC_UI.acceptService(this.consentTemplateId);
    }

    dispatchCustomEvent('consent-changed', {});
  }

  toggleConsentWindow(id) {
    // console.log('Toggling Consent Window.');
    const UC_UI = window.UC_UI || null;

    if (UC_UI !== null) {
      UC_UI.showSecondLayer(id);
    }
  }

  currentConsentState(id) {
    const UC_UI = window.UC_UI || null;
    let consentGiven = false;

    if (UC_UI !== null) {
      try {
        consentGiven = UC_UI.getServicesBaseInfo().find(data => (data.id === id)).consent.status;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    }
    // console.log('currentConsentState:',  consentGiven);

    return consentGiven;
  }
}

export default ConsentManager;
