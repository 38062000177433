import { addClass, removeClass, hasClass } from '../../tools/helpers';

const CLASSNAME_SPOT = '.org--spot-image__spot';
const CLASSNAME_CONTENT = '.org--spot-image__content';
const CLASSNAME_BUTTON = '.spot-button';
const CLASSNAME_CLOSE_BUTTON = '.org--spot-image__content--close';

class SpotImage {

  constructor(options = {}) {
    this.element = options.element;
    this.spots = this.element.querySelectorAll(CLASSNAME_SPOT);
    this.contentLayer = this.element.querySelectorAll(CLASSNAME_CONTENT);
    this.buttons = this.element.querySelectorAll(CLASSNAME_BUTTON);
    this.closeButtons = this.element.querySelectorAll(CLASSNAME_CLOSE_BUTTON);
  }

  init() {
    this.spots.forEach((spot) => {
      const content = spot.querySelector('.org--spot-image__content');
      const button = spot.querySelector('.spot-button');
      const imageCover = this.element.querySelector('.org--spot-image__buttons');
      const spotPositionLeft = spot.getAttribute('position-left');
      const spotPositionTop = spot.getAttribute('position-top');

      if (spotPositionLeft < '50%' && spotPositionTop < '50%') {
        addClass(content, 'top-left');
      }

      if (spotPositionLeft < '50%' && spotPositionTop > '50%') {
        addClass(content, 'bottom-left');
      }

      if (spotPositionLeft > '50%' && spotPositionTop < '50%') {
        addClass(content, 'top-right');
      }

      if (spotPositionLeft > '50%' && spotPositionTop > '50%') {
        addClass(content, 'bottom-right');
      }

      spot.addEventListener('click', () => {
        if (hasClass(content, 'hidden')) {
          addClass(this.contentLayer, 'hidden');
          removeClass(content, 'hidden');
          content.style.height = `${imageCover.offsetHeight / 2}px`;
        } else {
          addClass(content, 'hidden');
        }

        if (!hasClass(button, 'hidden')) {
          removeClass(this.buttons, 'hidden');
          addClass(button, 'hidden');
        } else {
          removeClass(button, 'hidden');
        }
      });

      spot.addEventListener('keydown', (event) => {

        if (event.key === 'Enter') {

          if (hasClass(content, 'hidden')) {
            addClass(this.contentLayer, 'hidden');
            removeClass(content, 'hidden');
            content.style.height = `${imageCover.offsetHeight / 2}px`;
          } else {
            addClass(content, 'hidden');
          }

          if (!hasClass(button, 'hidden')) {
            removeClass(this.buttons, 'hidden');
            addClass(button, 'hidden');
          } else {
            removeClass(button, 'hidden');
          }
        }
      });
    });
  }
}

export default SpotImage;
