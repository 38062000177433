import TemplateHandler from '../../TemplateHandler';
import { replacePlaceholder, dispatchCustomEvent, addClass, removeClass, generateSVGIcon } from '../../../tools/helpers';
import Pagination from '../../Pagination';
import ScrollHandler from '../../ScrollHandler';

const EVENTNAME_QUERY_CHANGE = 'search-query-changed';

class SearchView {
  constructor(options = {}) {
    this.element = options.element;
    this.itemTemplate = TemplateHandler.getTemplate('org--search__results__item');
    this.resultsContentList = this.element.querySelector('.org--search__results__content__list');
    this.resultsCount = this.element.querySelector('.org--search__results__counter');
    this.spinner = this.element.querySelector('.org--search__spinner');
    this.errorMessageServer = this.element.querySelector('.error-message--server');
    this.errorMessageNoResults = this.element.querySelector('.error-message--no-results');

    this.params = options.params;
    this.results = options.results || null;
    this.resultsPerPage = 10;
    this.paginationBefore = false;
  }

  init() {
  }

  update() {
    this.clear();
    this.renderItems();
  }

  clear() {
    this.resultsContentList.innerHTML = '';
  }

  renderItems() {
    if (this.results !== null && this.results.length > 0) {
      this.results.forEach((result) => {
        let template = this.itemTemplate;
        template = replacePlaceholder(template, 'resultURL', result.url);
        template = replacePlaceholder(template, 'resultLink', result.type === 'attachment' ? `${generateSVGIcon('icon-download')}${this.shortenURL(result.url)}` : this.shortenURL(result.url));
        template = replacePlaceholder(template, 'resultTitle', result.title);
        template = replacePlaceholder(template, 'resultDescription', this.concatHighlightedText(result.highlighted));
        this.resultsContentList.insertAdjacentHTML('beforeend', template);
      });

      if (!this.paginationBefore && this.results.length >= this.resultsPerPage) {
        this.addPagination();
      }

      if (this.pagination) {
        this.pagination.selectedPage();
      }

      if (this.results.length >= this.resultsPerPage) {
        this.showPagination();
      }

      this.updateCount();
    } else {
      this.updateCount();
      this.hidePagination();
      this.showNoResultsError();
    }
  }

  concatHighlightedText(items) {
    let concatText = '';
    items.forEach((item) => {
      let content = item;
      content += '… ';

      concatText += content;
    });

    return concatText;
  }

  updateCount() {
    const valueSpan = this.resultsCount.querySelector('span.count');
    const unitSpan = this.resultsCount.querySelector('span.unit');
    const unitValue = unitSpan.dataset.unit;

    if (this.results !== null) {
      valueSpan.innerHTML = this.params.totalHits;

      unitSpan.innerHTML = ` ${unitValue}`;
    }
  }

  addPagination() {
    this.pagination = new Pagination({
      element: this.element.querySelector('nav.pagination'),
      params: this.params,
      resultsPerPage: this.resultsPerPage,
      callback: () => {
        const scrollHandler = new ScrollHandler({
          element: this.element,
        });

        scrollHandler.scrollToTarget(window, this.element);
        dispatchCustomEvent(EVENTNAME_QUERY_CHANGE, { params: this.params });
      },
    });
    this.pagination.addPagination();

    if (!this.paginationBefore) {
      this.pagination.addEventListeners();
    }

    this.paginationBefore = true;
  }

  showSpinner() {
    addClass(this.spinner, 'shown');
  }

  hideSpinner() {
    removeClass(this.spinner, 'shown');
  }

  hideCount() {
    addClass(this.resultsCount, 'hidden');
  }

  showCount() {
    removeClass(this.resultsCount, 'hidden');
  }

  showServerError() {
    removeClass(this.errorMessageServer, 'hidden');
  }

  showNoResultsError() {
    removeClass(this.errorMessageNoResults, 'hidden');
  }

  hideServerError() {
    addClass(this.errorMessageServer, 'hidden');
  }

  hideNoResultsError() {
    addClass(this.errorMessageNoResults, 'hidden');
  }

  hidePagination() {
    addClass(this.element.querySelector('.pagination'), 'hidden');
  }

  showPagination() {
    removeClass(this.element.querySelector('.pagination'), 'hidden');
  }

  shortenURL(input) {
    const filename = input.replace(/^.*[\\/]/, '');

    let shorten = '';

    if (filename.length > 70) {
      shorten = filename.substring(0, 50);
      shorten += '&hellip;';
      shorten += filename.substring(filename.length - 15, filename.length);
    } else if (input.length > 70) {
      shorten = input.substring(0, 25);
      shorten += '&hellip;/';
      shorten += filename;
    } else {
      shorten = input;
    }

    return shorten;
  }
}

export default SearchView;
