import Swiper from 'swiper/bundle';
import { CONFIG } from '../config';

class PortraitSlider {
  constructor(options = {}) {
    this.element = options.element;
    this.swiperContainer = this.element.querySelector('.swiper-container');
    this.items = this.element.querySelectorAll('.org--portrait-slider__item');
  }

  init() {
    this.slider = new Swiper(this.swiperContainer, {
      slidesPerView: 1,
      slidesPerColumn: 1,
      breakpoints: {
        320: {
          spaceBetween: 20,
        },
        480: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 3,
          slidesPerColumn: 2,
        },
        992: {
          slidesPerView: 4,
          slidesPerColumn: 2,
        },
        1200: {
          slidesPerView: 4,
          slidesPerColumn: 2,
        },
        1300: {
          slidesPerView: 5,
          slidesPerColumn: 2,
        },
      },
      updateOnWindowResize: true,
      pagination: {
        el: this.element.querySelector('.org--portrait-slider__bullets'),
        clickable: true,
      },
    });

    window.addEventListener(CONFIG.events.viewportChange, () => {

      window.setTimeout(() => {
        this.slider.destroy();
      }, 500);

      window.setTimeout(() => {
        this.init();
      }, 500);
    });
  }
}

export default PortraitSlider;
