import Swiper from 'swiper/bundle';

class ExpertSlider {
  constructor(options = {}) {
    this.element = options.element;
    this.swiperContainer = this.element.querySelector('.swiper-container');
  }

  init() {
    this.expertSlider = new Swiper(this.swiperContainer, {
      slidesPerView: 1,
      breakpoints: {
        480: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
        992: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
        1200: {
          slidesPerView: 3,
          spaceBetween: 85,
        },
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.org--expert-slider__nav-icon.swiper-button-next',
        prevEl: '.org--expert-slider__nav-icon.swiper-button-prev',
      },
      loop: true,
    });

    window.addEventListener('resize', () => this.expertSlider.update());
  }

}

export default ExpertSlider;
