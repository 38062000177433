import 'url-search-params-polyfill';
import arrayFrom from 'array-from';

import DefaultSlider from '../DefaultSlider';

class HeroSlider {
  constructor(options = {}) {
    this.element = options.element;
    this.enableSlider = true;
    this.showArrows = this.element.dataset.showArrows || false;
    this.showBullets = this.element.dataset.showBullets || false;
    this.showLightbox = this.element.dataset.showLightbox || false;
    this.imageClick = this.element.dataset.imageClick || false;
    this.enableParams = this.element.dataset.paramsEnabled || false;
    this.query = null;
  }

  init() {
    this.initParams();
  }

  initParams() {
    if (window.location.search) {
      this.params = new URLSearchParams(window.location.search);
      this.queryAnchor = this.params.get('slide');
      const slides = this.element.querySelectorAll('.org--hero-slider__media__item');
      const currentSlide = arrayFrom(slides).findIndex(item => item.dataset.anchor === this.queryAnchor);
      if (currentSlide !== -1) {
        this.initialSlide = currentSlide;
      }

      this.initSlider();
    } else {
      this.initSlider();
    }
  }

  initSlider() {
    this.slider = new DefaultSlider({
      element: this.element,
      wrapper: this.element.querySelector('.org--hero-slider__wrapper'),
      showArrows: this.showArrows,
      showBullets: this.showBullets,
      showLightBox: this.showLightbox,
      imageClick: this.imageClick,
      itemClass: 'org--hero-slider__media__item',
      trackTabbable: false,
      initialSlide: this.initialSlide !== undefined ? this.initialSlide : null,
      options: {
        autoHeight: false,
        autoplay: {
          delay: 7000,
          disableOnInteraction: false,
        },
        gap: 0,
        loop: false,
      },
      // callback: () => this.initParams(),
    });
    this.slider.prepareDom();
  }
}

export default HeroSlider;
