import Accordion from 'accordion/src/accordion.mjs';

const CLASSNAME_CONTENT = 'org--accordion__content';
const CLASSNAME_ENABLED = 'accordion--enabled';

class DefaultAccordion {
  constructor(options = {}) {
    this.element = options.element;
    this.heightOffset = this.element.dataset.heightOffset || null;
    this.withMedia = this.element.dataset.withMedia || null;
  }

  init() {
    // accordion items containing images or videos require a timeout to accurately calculate their height
    if (this.withMedia) {
      window.setTimeout(() => this.createAccordion(), 500);
    } else {
      this.createAccordion();
    }
  }

  createAccordion() {
    // eslint-disable-next-line no-new
    new Accordion(this.element.querySelector(`.${CLASSNAME_CONTENT}`), {
      enabledClass: CLASSNAME_ENABLED,
      modal: true,
      noAria: false,
      heightOffset: this.heightOffset,
    });
  }
}

export default DefaultAccordion;
