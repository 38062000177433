/**
 * e-mail Linktemplate in FirstSpirit writes the e-mail address and the link text reversed into HTML.
 * the e-mail address is reverted again in the Browser and then displayed correctly.
 * implemented to protect the e-mail address from Bots.
 */

class EmailHandler {
  constructor(options = {}) {
    this.element = options.element;
  }

  init() {
    const email = this.readEmail(this.element.getAttribute('href'));
    const emailReversed = this.reverse(email);
    const emailOptions = this.readEmailOptions(this.element.getAttribute('href'));

    const revertedMailText = this.reverse(this.element.innerText.trim());
    const svg = this.element.querySelector('svg');
    if (svg) {
      // we can not use 'prepend" because it does not work in IE
      const emailText = this.element.lastChild;
      this.element.removeChild(emailText);
      const newMail = document.createTextNode(revertedMailText);
      this.element.appendChild(newMail);
    } else {
      this.element.innerText = revertedMailText;
    }

    this.element.href = `mailto:${emailReversed}${emailOptions}`;
  }


  reverse(str) {
    return str.split('').reverse().join('');
  }

  readEmailOptions(string) {
    const result = string.split('?');

    return result[1] ? `?${result[1]}` : '';
  }

  readEmail(mail) {
    const mailWithoutOpt = mail.split('?')[0];
    return mailWithoutOpt.replace('mailto:', '');
  }
}

export default EmailHandler;
