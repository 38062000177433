import arrayFrom from 'array-from';

import DefaultSlider from '../DefaultSlider';
import { addClass, getWindowSize, removeClass } from '../../tools/helpers';
import { BREAKPOINTS, CONFIG } from '../config';

class Stage {
  constructor(options = {}) {
    this.element = options.element;
    this.enableSlider = true;
    this.showArrows = false;
    this.showBullets = this.element.dataset.showBullets || false;
    this.showLightbox = this.element.dataset.showLightbox || false;
    this.imageClick = this.element.dataset.imageClick || false;
  }

  init() {
    if (this.enableSlider) {
      this.initDescription();
      this.initSlider();
    }
  }

  initDescription() {
    this.descItems = this.element.querySelectorAll('.org--stage__description__item');

    const container = document.createElement('div');
    addClass(container, 'org--stage__description__wrapper container');

    const row = document.createElement('div');
    addClass(row, 'org--stage__description__row row');

    arrayFrom(this.descItems).forEach((item) => {
      row.appendChild(item);
    });

    container.appendChild(row);
    this.element.appendChild(container);

    // to fix height of container with descriptions items and avoid jumping of next content
    window.setTimeout(() => {
      this.fixDescriptionHeight();

      window.addEventListener('resize', () => {
        this.fixDescriptionHeight();
      });
    }, 500);
  }

  /*
  function helps to avoid jumping of content under description
  fixes the height of container according to highest description element
   */
  fixDescriptionHeight() {
    const diffBetweenPaddings = 60;
    const paddingBottom = 10;
    const wrapper = this.element.querySelector('.org--stage__description__wrapper');
    if (this.checkViewport() === 'LG' || this.checkViewport() === 'XL' || this.checkViewport() === 'XXL') {
      const descContents = this.element.querySelectorAll('.org--stage__description__item__content');
      const heightsOfDescItems = arrayFrom(descContents)
        .map((elem) => {
          const descItem = elem.closest('.org--stage__description__item');
          if (descItem.classList.contains('active')) {
            return elem.offsetHeight + diffBetweenPaddings + paddingBottom;
          }

          return elem.offsetHeight + paddingBottom;
        });
      /* eslint-disable */
      const maxHeight = function (arr) {
        // eslint-disable-next-line prefer-spread
        return Math.max.apply(Math, arr);
      };

      wrapper.style.minHeight = `${maxHeight(heightsOfDescItems)}px`;
    } else {
      wrapper.style.minHeight = 0;
    }
  }

  checkViewport() {
    const windowWidth = getWindowSize().width;
    for (const viewport of CONFIG.viewports) {
      if (windowWidth <= viewport.width) {
        return viewport.name;
      }
    }

    return BREAKPOINTS.xxl;
  }

  initSlider() {
    this.slider = new DefaultSlider({
      element: this.element,
      wrapper: this.element.querySelector('.org--stage__wrapper'),
      showArrows: this.showArrows,
      showBullets: this.showBullets,
      showLightBox: this.showLightbox,
      itemClass: 'org--stage__media__item',
      imageClick: false,
      trackTabbable: true,
      options: {
        autoHeight: false,
        autoplay: {
          delay: 7000,
          disableOnInteraction: false,
        },
        gap: 0,
        loop: true,
        loopAdditionalSlides: 0,
      },
    });

    this.slider.prepareDom();

    window.setTimeout(() => {
      this.slider.slider.on('slideChange', () => {
        arrayFrom(this.descItems).forEach((item) => {
          removeClass(item, 'active');
        });
        addClass(this.descItems[this.slider.slider.realIndex], 'active');
      });

      arrayFrom(this.descItems).forEach((item, index) => {
        item.addEventListener('click', () => {
          this.slider.slider.slideToLoop(index);
        });
      });
    }, 500);

  }
}

export default Stage;
