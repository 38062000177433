import DefaultSlider from '../DefaultSlider';

class ContentTeaserSlider {
  constructor(options = {}) {
    this.element = options.element;
    this.enableSlider = true;
    this.showArrows = this.element.dataset.showArrows.toString() || 'false';
    this.showBullets = this.element.dataset.showBullets.toString() || 'false';
    this.showLightbox = this.element.dataset.showLightbox.toString() || 'false';
    this.imageClick = this.element.dataset.imageClick.toString() || 'false';
  }

  init() {
    if (this.enableSlider) {
      this.initSlider();
    }
  }

  initSlider() {
    const slider = new DefaultSlider({
      element: this.element,
      wrapper: this.element.querySelector('.org--content-teaser__wrapper'),
      showArrows: this.showArrows,
      showBullets: this.showBullets,
      showLightBox: this.showLightbox,
      imageClick: this.imageClick,
      appendNavToElement: true,
      itemClass: 'org--content-teaser__item',
      trackTabbable: true,
      options: {
        autoHeight: true,
        autoplay: {
          delay: 7000,
          disableOnInteraction: false,
        },
        gap: 0,
        loop: false,
      },
    });
    slider.prepareDom();
  }
}

export default ContentTeaserSlider;
