import DefaultNavigation from '../DefaultNavigation';
import { toggleClass } from '../../tools/helpers';

class HeaderNavigation extends DefaultNavigation {
  constructor(options = {}) {
    super(options);
  }

  init() {
    super.init();
    this.addToggle();
  }

  addToggle() {
    this.mobileNavigation = this.element.querySelector('.navigation-mobile');
    this.mobileNavigation.addEventListener('click', () => this.toggleNavigation());
  }

  toggleNavigation() {
    toggleClass(this.mobileNavigation, 'icon-closed');

    if (this.mobileNavigation.getAttribute('aria-expanded') === 'false') {
      this.mobileNavigation.setAttribute('aria-expanded', true);
    } else if (this.mobileNavigation.getAttribute('aria-expanded') === 'true') {
      this.mobileNavigation.setAttribute('aria-expanded', false);
    }

    const wrapperNavigation = this.mobileNavigation.parentNode.querySelector('.wrapper-navigation');

    toggleClass(wrapperNavigation, 'in');
  }
}

export default HeaderNavigation;
