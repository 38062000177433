import arrayFrom from 'array-from';
import { addClass, removeClass, hasClass, generateSVGIcon } from '../../tools/helpers';

export const CLASSNAME_FILTER = 'org--modelling-teaser-group__filter';
export const CLASSNAME_FILTERS_WRAPPER = '.org--modelling-teaser-group__filters-wrapper';
export const CLASSNAME_FILTERS_ELEMENT = '.org--modelling-teaser-group__filters';
export const CLASSNAME_FILTER_NOT_ACTIVE = 'org--modelling-teaser-group__filter--not-active';
export const CLASSNAME_TEASER = '.mol--modelling-teaser-item';
export const CLASSNAME_TEASER_HIDDEN = 'mol--modelling-teaser-item--hidden';
export const CLASSNAME_MESSAGE = '.org--modelling-teaser-group__filters-message';

class ModelingFilter {
  constructor(options = {}) {
    this.element = options.element;
    this.filtersElement = this.element.querySelector(CLASSNAME_FILTERS_ELEMENT);
    this.filtersWrapper = this.element.querySelector(CLASSNAME_FILTERS_WRAPPER);
    this.filtersMessage = this.element.querySelector(CLASSNAME_MESSAGE);
    this.filtersButtons = [];
    this.teasers = this.element.querySelectorAll(CLASSNAME_TEASER);
    this.categories = [];
    // eslint-disable-next-line no-restricted-globals
    this.url = new URL(location);
  }

  init() {
    this.readCategoriesFromTeasers();
    window.setTimeout(() => {
      this.prepareFilters();
    }, 500);
    window.setTimeout(() => {
      this.initFilters();
    }, 1000);
  }

  readCategoriesFromTeasers() {
    arrayFrom(this.teasers).forEach((teaser) => {
      const categoryFromTeaser = {};
      categoryFromTeaser.name = teaser.dataset.category;
      categoryFromTeaser.color = teaser.dataset.categoryColor;
      if (!this.categories.find(elem => elem.name === categoryFromTeaser.name)) {
        this.categories.push(categoryFromTeaser);
      }
    });
    // we don't need filter if there is only one category
    if (this.categories.length < 2) {
      addClass(this.filtersElement, 'hidden');
    }

    this.categories.sort((prev, next) => {
      if (prev.name < next.name) {
        return -1;
      }

      return 1;
    });
    this.activeCategories = this.categories.map(item => item.name);
    const categoriesFromUrl = this.url.searchParams.get('category') ? this.url.searchParams.get('category').split(';') : null;
    if (categoriesFromUrl && categoriesFromUrl.length > 0) {
      this.activeCategories = categoriesFromUrl;
      this.updateTeasers();
    } else {
      this.updateUrl();
    }
  }

  prepareFilters() {
    this.categories.forEach((category) => {
      const button = document.createElement('button');
      addClass(button, CLASSNAME_FILTER);
      if (!this.activeCategories.includes(category.name)) {
        addClass(button, CLASSNAME_FILTER_NOT_ACTIVE);
      }

      button.setAttribute('data-category', category.name);
      button.style.backgroundColor = category.color;
      button.style.borderColor = category.color;
      const svgSpan = document.createElement('span');
      addClass(svgSpan, 'org--modelling-teaser-group__filter--icon org--modelling-teaser-group__filter--icon-not-active');
      svgSpan.innerHTML = generateSVGIcon('icon-circle', null, null, `stroke:${category.color};`);
      const svgSpanActive = document.createElement('span');
      addClass(svgSpanActive, 'org--modelling-teaser-group__filter--icon org--modelling-teaser-group__filter--icon-active');
      svgSpanActive.innerHTML = generateSVGIcon('icon-check-circle');
      const textSpan = document.createElement('span');
      textSpan.innerText = category.name;
      button.appendChild(svgSpan);
      button.appendChild(svgSpanActive);
      button.appendChild(textSpan);
      this.filtersWrapper.appendChild(button);
      this.filtersButtons.push(button);
    });
  }

  initFilters() {
    this.filtersButtons.forEach((filtersButton) => {
      filtersButton.addEventListener('click', () => {
        const currentFilter = filtersButton.dataset.category;
        if (this.activeCategories.includes(currentFilter)) {
          this.activeCategories = this.activeCategories.filter(category => category !== currentFilter);
          addClass(filtersButton, CLASSNAME_FILTER_NOT_ACTIVE);
        } else {
          this.activeCategories.push(currentFilter);
          removeClass(filtersButton, CLASSNAME_FILTER_NOT_ACTIVE);
        }

        if (this.activeCategories.length < 1) {
          removeClass(this.filtersMessage, 'hidden');
        } else if (this.activeCategories.length > 0 && !hasClass(this.filtersMessage, 'hidden')) {
          addClass(this.filtersMessage, 'hidden');
        }

        this.updateTeasers();
        this.updateUrl();
      });
    });
  }

  updateTeasers() {
    this.teasers.forEach((teaser) => {
      const teaserCategory = teaser.dataset.category;
      if (!this.activeCategories.includes(teaserCategory)) {
        addClass(teaser, CLASSNAME_TEASER_HIDDEN);
      }

      if (this.activeCategories.includes(teaserCategory) && hasClass(teaser, CLASSNAME_TEASER_HIDDEN)) {
        removeClass(teaser, CLASSNAME_TEASER_HIDDEN);
      }

      removeClass(teaser, 'fadeIn');

      window.setTimeout(() => {
        addClass(teaser, 'fadeIn');
      }, 100);
    });
  }

  updateUrl() {
    this.url.searchParams.set('category', this.activeCategories.join(';'));
    // eslint-disable-next-line no-restricted-globals
    history.pushState('', '', this.url);
  }
}

export default ModelingFilter;
