/*
* split one number from cms to 2, 3 etc
 */

class JobTeaserNumber {
  constructor(options = {}) {
    this.element = options.element;
    this.numberContaineer = this.element.querySelector('.org--career-job-teaser__numbers');
    this.sourceNumber = this.element.querySelector('.org--career-job-teaser__source-number');
  }

  init() {
    if (this.sourceNumber) {
      this.splitNumber();
    }
  }

  splitNumber() {
    const jobNumber = this.sourceNumber.innerHTML.trim();
    const jobNumberArr = jobNumber.split('');
    const numberDiv = jobNumberArr.map(number => `<div class="org--career-job-teaser__number">${number}</div>`);
    this.numberContaineer.removeChild(this.sourceNumber);
    this.numberContaineer.insertAdjacentHTML('afterbegin', numberDiv.join(''));
  }
}

export default JobTeaserNumber;
